<template>
  <b-container class="fade-active fade-enter">
    <b-row class="mt-5 justify-content-center">
      <b-col sm="4" class="mt-5">
        <b-overlay :show="loginForm.state == dataStates.LOADING || captcha.state == dataStates.LOADING">
          <b-card no-body>
            <b-card-body class="pt-3">
              <h3 class="mb-0">Авторизация</h3>
              <div class="text-right mb-1">
                <small><b-link @click="onPassIsLostClick">Забыли пароль?</b-link></small>
              </div>
              <b-form @submit.prevent="onLoginFormSubmit">
              <b-form-group>
                <b-form-input required placeholder="Имя пользователя" :state="Boolean(loginForm.login)" v-model="loginForm.login" size="sm"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input required placeholder="Пароль" :state="Boolean(loginForm.password)" v-model="loginForm.password" type="password" size="sm"></b-form-input>
              </b-form-group>
              <b-row class="mb-3">
                <b-col class="pr-1">
                  <b-form-input required placeholder="Капча" :state="Boolean(loginForm.captcha)" v-model="loginForm.captcha" size="sm"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-img :src="captcha.pic" style="width: 100%; height: 32px;border-radius: 3.2px;" fluid alt="Captcha"></b-img>
                </b-col>
              </b-row>
              <b-button type="submit" size="sm" block :disabled="loginForm.state == dataStates.LOADING" variant="dark">Далее</b-button>
            </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import {apiRequest, admUrl} from '../main.js'

  export default {
    name: 'LoginPage',
    data() {
      return {
        redirectTo: false,
        captcha: {
          state: null,
          pic: null
        },
        loginForm: {
          state: null,
          login: null,
          password: null,
          captcha: null
        }
      }
    },
    mounted() {
      this.getPageData()
      if(this.$route.hash == '#lumos') {
        this.redirectTo = true
      }
    },
    beforeDestroy () {
    },
    watch: {
    },
    methods: {
      getPageData() {
        this.captcha.state = this.dataStates.LOADING
        apiRequest.get('page/login')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.captcha.state = this.dataStates.LOADED
            this.captcha.pic = response.data['data']['captcha']
            if(response.data['data']['is_logged_in']) {
              this.$router.push({ name: 'DashboardPage' })
            } else {
              this.$el.classList.remove('fade-enter')
            }
          } else {
            throw 'Error'
          }
        })
        .catch(() => {
          this.captcha.state = this.dataStates.ERROR
        })
      },
      onPassIsLostClick() {
        this.$router.push({ name: 'RestorePasswordPage' })
      },
      onLoginFormSubmit() {
        let args = {
          'username': this.loginForm.login,
          'password': this.loginForm.password,
          'captcha': this.loginForm.captcha
        }
        let data = this.serializeParams(args)
        this.loginForm.state = this.dataStates.LOADING
        this.captcha.state = this.dataStates.LOADING
        apiRequest.post('login', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            setTimeout(() => {
              this.$el.classList.add('fade-enter')
            }, 1300)
            setTimeout(() => {
              if(!this.redirectTo) {
                this.$router.push({ name: 'DashboardPage' })
              } else {
                window.location.href = admUrl
              }
            }, 1500)
          } else {
            if(response.data['data'] != null && 'captcha' in response.data['data']) {
              this.captcha.pic = response.data['data']['captcha']
              this.loginForm.captcha = null
            }
            this.loginForm.state = this.dataStates.LOADED
            this.captcha.state = this.dataStates.LOADED
          }
          this.showAlert(success, response.data['message'])
        })
        .catch(() => {
          this.captcha.state = this.dataStates.ERROR
        })
      }
    }
  }
</script>

<style scoped>
  .fade-active {
    transition: opacity .2s;
  }
  .fade-enter {
    opacity: 0;
  }
</style>
