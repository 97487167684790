<template>
  <b-container fluid class="mb-3">
    <b-row>
      <b-col class="mt-5" v-if="pageState == dataStates.LOADED">
        <template v-if="status == 'banned'">
          <div class="text-center">
            <h2>Ошибка!</h2>
            <span>Ваш аккаунт заблокирован. Для разблокировки свяжитесь с администрацией.</span>
          </div>
        </template>
        <template v-else-if="status == 'not_activated'">
          <b-row class="justify-content-center mt-2">
            <b-col sm="12" md="10" lg="6" xl="3">
              <b-card v-if="isEmailVerified" title="Активация">
                <b-overlay no-wrap :show="state == dataStates.LOADING"></b-overlay>
                <template v-if="invoice.address == null">
                  <label>Выберите наиболее удобный способ оплаты:</label>
                  <b-form-radio-group stacked id="payment-type-radio-group" v-model="paymentType" :options="paymentTypes" name="radio-options"></b-form-radio-group>
                  <b-button class="mt-3" @click="onSelectPaymentTypeClick" size="sm" block :disabled="!Boolean(paymentType)" variant="dark">Далее</b-button>
                </template>
                <template v-else>
                  <template v-if="invoice.address != null">
                    <template v-if="invoice.timeLeft <= 1 || invoice.state == 'failed'">
                      Время действия ранее созданного платежа истекло.
                      <div class="text-right mt-2">
                        <b-button variant="dark" class="mr-1" @click="onReissueButtonClick" type="button" size="sm">Пересоздать платёж</b-button>
                      </div>
                    </template>
                    <template v-else>
                      <span>Для осуществления оплаты необходимо внести на указанный адрес <b>{{ invoice.paymentType }}</b> точную сумму монет, указанную ниже. Сообщать об оплате нет необходимости.</span>
                      <b-row class="mt-3 mb-2">
                        <b-col class="pr-1">
                          <b-form-group class="mb-0" label-class="text-muted" label="Адрес оплаты">
                            <b-input-group size="sm">
                              <b-input-group-append>
                                <b-button v-b-tooltip.hover title="Скопировать" @click="copyText(invoice.address)" variant="dark"><b-icon icon="textarea-t"></b-icon></b-button>
                              </b-input-group-append>
                              <b-form-input class="white-bg" v-model="invoice.address" readonly></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4" class="pl-1 text-right">
                          <b-form-group class="mb-0" label-class="text-muted" label="Сумма оплаты">
                            <b-input-group size="sm">
                              <b-form-input size="sm" class="white-bg text-right" v-model="invoice.amount" readonly></b-form-input>
                              <b-input-group-prepend>
                                <b-button v-b-tooltip.hover title="Скопировать" @click="copyText(invoice.amount)" variant="dark"><b-icon icon="textarea-t"></b-icon></b-button>
                              </b-input-group-prepend>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <div class="text-center" style="line-height: 1">
                        <small><i>Статус оплаты:</i> {{ getInvoiceStatusStr(this.invoice.status).toLowerCase() }}<br/><i>На оплату осталось:</i> {{ timeLeftForHuman }}</small>
                      </div>
                      <div class="text-right mt-2">
                        <b-button variant="danger" :disabled="isInvoiceDisabled" @click="onPaymentCancelButtonClick" type="button" size="sm">Отменить</b-button>
                      </div>
                    </template>
                  </template>
                </template>
              </b-card>
              <b-card v-else title="Подтверждение почты">
                <b-overlay no-wrap :show="state == dataStates.LOADING"></b-overlay>
                На указанную Вами почту было отправлено сообщение с кодом. Если спустя долгое время вы не увидите сообщение, пожалуйста удостоверьтесь что его нет в папке "спам". Введите полученный код в окно ниже. <b-link @click="onResendButtonClick">Отправить код ещё раз?</b-link>
                <b-form @submit.prevent="onCodeSubmit" class="mt-3">
                  <b-form-group label="Код подтверждения">
                    <b-input-group size="sm">
                      <b-form-input required :state="Boolean(emailForm.code)" v-model="emailForm.code" placeholder="Код из письма" type="text">
                      </b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-row>
                    <b-col>
                      <b-row class="mb-3">
                        <b-col class="pr-1">
                          <b-form-input required placeholder="Капча" :state="Boolean(emailForm.captcha)" v-model="emailForm.captcha" size="sm"></b-form-input>
                        </b-col>
                        <b-col class="pl-1 pr-1">
                          <b-img :src="captcha.pic" style="width: 100%; height: 32px;border-radius: 3.2px;" fluid alt="Captcha"></b-img>
                        </b-col>
                        <b-col class="pl-1">
                          <b-button variant="dark" size="sm" type="submit" block>Далее</b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import {apiRequest} from '../main.js'
  import Constants from '../constants.js'

  export default {
    name: 'BadAccountPage',
    data() {
      return {
        captcha: {
          state: null,
          pic: null
        },
        emailForm: {
          captcha: null,
          code: null
        },
        state: null,
        status: null,
        isEmailVerified: false,
        pageState: null,
        paymentTypes: [],
        paymentType: null,
        updateTimeLeftTimer: null,
        isConfirmButtonDisabled: false,
        updateInvoiceTimer: null,
        invoice: {
          id: null,
          timeLeft: null,
          status: null,
          address: null,
          paymentType: null,
          amount: null,
          amountUsd: null
        }
      }
    },
    mounted() {
      if(this.updateTimeLeftTimer) {
        clearInterval(this.updateTimeLeftTimer)
      }
      this.updateTimeLeftTimer = setInterval(this.updateTimeLeft, 1000)
      this.getProfileStatus()
    },
    computed: {
      isInvoiceDisabled() {
        return ['unconfirmed', 'done', 'insufficient_funds'].includes(this.invoice.status)
      },
      timeLeftForHuman() {
        let res = new Date(this.invoice.timeLeft * 1000).toISOString().substr(11, 8)
        return res
      }
    },
    beforeDestroy() {
      clearInterval(this.updateTimeLeftTimer)
      if(this.updateInvoiceTimer) {
        clearInterval(this.updateInvoiceTimer)
      }
    },
    methods: {
      onCodeSubmit() {
        this.isConfirmButtonDisabled = true
        this.state = this.dataStates.LOADING
        this.captcha.state = this.dataStates.LOADING
        apiRequest.post('verify_email', `code=${this.emailForm.code}&captcha=${this.emailForm.captcha}`)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.isEmailVerified = true
          } else {
            this.captcha.pic = response.data['data']['captcha']
            this.emailForm.captcha = null
          }
          this.showAlert(success, response.data['message'])
          this.state = this.dataStates.LOADED
          setTimeout(() => {
            this.isConfirmButtonDisabled = false
          }, 1500)
        })
      },
      getPageData() {
        this.captcha.state = this.dataStates.LOADING
        apiRequest.get('page/email_verification')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.captcha.state = this.dataStates.LOADED
            this.captcha.pic = response.data['data']['captcha']
          } else {
            throw 'Error'
          }
        })
        .catch(() => {
          this.captcha.state = this.dataStates.ERROR
        })
      },
      onSelectPaymentTypeClick() {
        let args = {
          'type': 'activation',
          'method': this.paymentType
        }
        let data = this.serializeParams(args)
        this.state = this.dataStates.LOADING
        apiRequest.post('put_payment', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.invoice.id = response.data['data']['invoice_id']
            this.invoice.address = response.data['data']['address']
            this.invoice.timeLeft = response.data['data']['time_left']
            this.invoice.status = response.data['data']['status']
            this.invoice.amount = response.data['data']['amount']
            this.invoice.amountUsd = response.data['data']['amount_usd']
            this.invoice.paymentType = response.data['data']['payment_type']
            if(this.invoice.address) {
              if(this.updateInvoiceTimer) {
                clearInterval(this.updateInvoiceTimer)
              }
              this.updateInvoiceTimer = setInterval(this.getInvoiceInfo, Constants.UPDATE_INVOICE_INFO_TIME)
            }
          } else {
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.state = this.dataStates.LOADED
        })
      },
      onPaymentCancelButtonClick() {
        this.$bvModal.msgBoxConfirm('Вы уверены что хотите отменить оплату?', {
          title: 'Подтверждение',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'dark',
          footerClass: 'p-2',
          hideHeaderClose: false,
        })
        .then(value => {
          if(value) {
            this.state = this.dataStates.LOADING
            apiRequest.post('cancel_invoice', 'type=activation')
            .then(response => {
              if(response.data['status'] == 'success') {
                this.paymentType = null
                this.invoice.id = null
                this.invoice.address = null
                this.invoice.timeLeft = null
                this.invoice.status = null
                this.invoice.amount = null
                this.invoice.amountUsd = null
                this.invoice.paymentType = null
              }
            })
            .finally(() => {
              this.state = this.dataStates.LOADED
            })
          }
        })
      },
      onReissueButtonClick() {
        this.state = this.dataStates.LOADING
        apiRequest.post('reissue_payment', 'type=activation')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.invoice.id = response.data['data']['invoice_id']
            this.invoice.address = response.data['data']['address']
            this.invoice.timeLeft = response.data['data']['time_left']
            this.invoice.status = response.data['data']['status']
            this.invoice.amount = response.data['data']['amount']
            this.invoice.amountUsd = response.data['data']['amount_usd']
            this.invoice.paymentType = response.data['data']['payment_type']
          }
        })
        .finally(() => {
          this.state = this.dataStates.LOADED
        })
      },
      onResendButtonClick() {
        this.state = this.dataStates.LOADING
        apiRequest.get('resend_code')
        .then(response => {
          this.showAlert(response.data['status'] == 'success', response.data['message'])
          this.state = this.dataStates.LOADED
        })
      },
      getInvoiceStatusStr(name) {
        switch(name) {
          case 'ready':
            return 'Сформировано'
          case 'unconfirmed':
            return 'Ожидаем подтверждения'
          case 'done':
            return 'Выполнено'
          case 'insufficient_funds':
            return 'Ожидаем средства'
          case 'failed':
            return 'Истекло время'
          case 'canceled':
            return 'Отменено'
        }
        return '-'
      },
      updateTimeLeft() {
        if(this.invoice.timeLeft !== null) {
          this.invoice.timeLeft--;
        }
      },
      getInvoiceInfo() {
        this.state = this.dataStates.LOADING
        apiRequest.post('get_active_invoice', 'type=activation')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.invoice.id = response.data['data']['invoice_id']
            this.invoice.address = response.data['data']['address']
            this.invoice.status = response.data['data']['status']
            this.invoice.amount = response.data['data']['amount']
            this.invoice.amountUsd = response.data['data']['amount_usd']
            this.invoice.paymentType = response.data['data']['payment_type']
            this.invoice.timeLeft = response.data['data']['time_left']
            if(this.invoice.status == 'done') {
              this.$router.push({name: 'DashboardPage'})
            }
          }
        })
        .finally(() => {
          this.state = this.dataStates.LOADED
        })
      },
      onLogoutButtonClick() {
        apiRequest.get('logout')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.$router.push({name: 'LoginPage'})
          }
        })
      },
      getPaymentTypes() {
        apiRequest.get('get_payment_methods')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentTypes = response.data['data']
          }
        })
      },
      getProfileStatus() {
        this.pageState = this.dataStates.LOADING
        apiRequest.get('get_status')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.status = response.data['data']['status']
            this.isEmailVerified = response.data['data']['is_email_verified']
            if(!this.isEmailVerified) {
              this.getPageData()
            }
            if(this.status == 'activated') {
              this.$router.push({ name: 'DashboardPage' })
            } else {
              if(this.status == 'not_activated') {
                this.getPaymentTypes()
                this.getInvoiceInfo()
                if(this.isEmailVerified) {
                  if(this.updateInvoiceTimer) {
                    clearInterval(this.updateInvoiceTimer)
                  }
                  this.updateInvoiceTimer = setInterval(this.getInvoiceInfo, Constants.UPDATE_INVOICE_INFO_TIME)
                }
              }
              this.pageState = this.dataStates.LOADED
            }
          } else {
            this.$router.push({name: 'LoginPage'})
          }
        })
      }
    }
  }
</script>
