<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-overlay :show="stats.state == dataStates.LOADING">
          <b-card class="pb-0" card-body-class="mb-0">
            <b-row class="justify-content-center">
              <b-col class="mb-3" lg="6" xl="2">
                <b-form-select size="sm" v-model="filterForm.keyType" :options="keyTypes">
                </b-form-select>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-form-select size="sm" v-model="filterForm.asset" :options="visibleAssets">
                </b-form-select>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-form-select size="sm" v-model="filterForm.settingType" :options="settingTypes">
                </b-form-select>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-row>
                  <b-col :class="filterForm.createdAt.from != null ? 'pr-0' : ''">
                    <b-form-datepicker placeholder="Начало периода" locale="ru" :date-format-options="defaultDateTimeFormat" :style="filterForm.createdAt.from != null ? 'border-top-right-radius: 0px;border-bottom-right-radius: 0px;' : ''" size="sm" v-model="filterForm.createdAt.from">
                    </b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="pl-0" v-if="filterForm.createdAt.from != null">
                    <b-button block variant="dark" @click="filterForm.createdAt.from=null" size="sm" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;">x</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-row>
                  <b-col :class="filterForm.createdAt.to != null ? 'pr-0' : ''">
                    <b-form-datepicker placeholder="Конец периода" locale="ru" :date-format-options="defaultDateTimeFormat" :style="filterForm.createdAt.to != null ? 'border-top-right-radius: 0px;border-bottom-right-radius: 0px;' : ''" size="sm" v-model="filterForm.createdAt.to">
                    </b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="pl-0" v-if="filterForm.createdAt.to != null">
                    <b-button block variant="dark" @click="filterForm.createdAt.to=null" size="sm" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;">x</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-button @click="onApplyButtonClick" variant="dark" block size="sm">Применить</b-button>
              </b-col>
            </b-row>
            <b-table head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="stats.items" :fields="stats.fields" show-empty :emptyText="emptyTableText">
              <template #cell(income)="data">
                {{ parseFloat(data.value || '0').toFixed(3) }} USDT
              </template>
              <template #empty="scope">
                <center>
                  {{ scope.emptyText }}
                </center>
              </template>
              <template #custom-foot>
                <b-tr>
                  <b-th colspan="2"><span class="sr-only">-</span></b-th>
                  <b-th colspan="1">{{ parseFloat(stats.incomesSum || '0').toFixed(3) }} USDT</b-th>
                  <b-th colspan="1"><span class="sr-only">-</span></b-th>
                </b-tr>
              </template>
            </b-table>

            <!--
              <small><b>Внимание!</b> Данные из статистики могут отличаться от данных доходности на странице кабинета, так как в кабинете идёт расчёт относительно времени по Гринвичу.</small>
              !-->
            <b-pagination @input="onPageChanged" class="mb-0 mt-2" align="right"
              size="sm" v-model="stats.currentPage" :total-rows="stats.totalCount" :per-page="stats.itemsPerPage">
            </b-pagination>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'StatsPage',
    data() {
      return {
        settingTypes: [
          { text: 'Spot & Futures', value: 'sf' },
          { text: 'Infinity Trade', value: 'it' }
        ],
        currentFilterSettings: {
          settingType: 'sf',
          keyType: null,
          asset: null,
          createdAt: {
            from: null,
            to: null
          }
        },
        filterForm: {
          settingType: 'sf',
          keyType: null,
          asset: null,
          createdAt: {
            from: null,
            to: null
          }
        },
        assets: [],
        visibleAssets: [],
        keyTypes: [],
        stats: {
          currentPage: 1,
          itemsPerPage: null,
          incomesSum: null,
          totalCount: null,
          state: null,
          items: [],
          fields: [
            {
              label: 'Биржа',
              key: 'key_type',
            },
            {
              label: 'Валютная пара',
              key: 'asset',
            },
            {
              label: 'Доход',
              key: 'income',
            },
            {
              label: 'Дата',
              key: 'created_at',
            },
          ]
        }
      }
    },
    mounted() {
      this.getStats()
    },
    watch: {
      'filterForm.keyType': function(value) {
        this.modAssetsOnKeyTypeChange(value)
      },
      'visibleAssets': function(value) {
        if(value.length == 0 || value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Все валюты',
            'disabled': false
          }
          value.unshift(itm)
        }
      },
      'keyTypes': function(value) {
        if(value.length == 0) {
          let itm = {
            'value': null,
            'text': 'Биржи недоступны',
            'disabled': true
          }
          value.unshift(itm)
        } else if(value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Все биржи',
            'disabled': false
          }
          value.unshift(itm)
        }
      }
    },
    methods: {
      onRefreshButtonClick() {
        this.getStats()
      },
      onPageChanged(value) {
        this.stats.currentPage = value
        this.getStats()
      },
      modAssetsOnKeyTypeChange(value) {
        let results = []
        for (let asset of this.assets) {
          if(value == null || asset['key_type_id'] == value) {
            let title = this.symbols[asset['symbol_id']]
            results.push({
              'value': asset['id'],
              'text': title
            })
          }
        }
        this.visibleAssets = results
      },
      onApplyButtonClick() {
        Object.assign(this.currentFilterSettings, this.filterForm)
        this.getStats()
      },
      getStats() {
        let args = {
          'key_type': this.currentFilterSettings.keyType,
          'asset': this.currentFilterSettings.asset,
          'from': this.currentFilterSettings.createdAt.from,
          'to': this.currentFilterSettings.createdAt.to,
          'type': this.currentFilterSettings.settingType,
          'page': this.stats.currentPage
        }
        let data = this.serializeParams(args)
        this.stats.state = this.dataStates.LOADING
        this.visibleAssets = []
        apiRequest.post(`get_stats`, data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.stats.incomesSum = response.data['data']['sum']
            this.stats.totalCount = response.data['data']['count']
            this.stats.itemsPerPage = response.data['data']['per_page']
            this.stats.items = response.data['data']['stats']
            this.assets = response.data['data']['assets']
            this.symbols = response.data['data']['symbols']
            this.keyTypes = response.data['data']['key_types']
            this.stats.state = this.dataStates.LOADED
            this.modAssetsOnKeyTypeChange(this.filterForm.keyType)
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
      }
    }
  }
</script>
