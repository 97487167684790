<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col lg="12">
        <b-card>
          <b-overlay no-wrap :show="tickets.state == dataStates.LOADING">
          </b-overlay>
          <b-table clickable @row-clicked="onTicketsRowClick" id="tickets-table" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1 mt-2 clickable" small hover :items="tickets.items" :fields="tickets.fields" show-empty :emptyText="emptyTableText">
            <template #empty="scope">
              <center>
                {{ scope.emptyText }}
              </center>
            </template>
            <template #cell(is_closed)="data">
              {{ data.value ? 'Закрыто' : 'Открыто' }}
            </template>
          </b-table>
          <b-pagination @input="onTicketsPageChanged" size="sm" align="right" v-model="tickets.currentPage" :total-rows="tickets.totalCount" :per-page="tickets.itemsPerPage" aria-controls="tickets-table"></b-pagination>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="ticket-replies-modal" hide-footer title="Обращение" size="lg">
      <b-overlay no-wrap :show="replies.state == dataStates.LOADING">
      </b-overlay>
      <template v-if="!newReply.isTicketClosed">
        <div class="text-right">
          <small><b-link @click="onCloseTicketButtonClick">[Закрыть тикет]</b-link></small>
        </div>
        <b-form @submit.prevent="onNewReplyFormSubmit" class="mt-2 mb-2">
          <b-form-textarea size="sm" v-model="newReply.text" :state="Boolean(newReply.text)" placeholder="Введите текст, до 500 символов" rows="3" max-rows="4"></b-form-textarea>
          <b-row class="mt-2">
            <b-col>
              <b-row>
                <b-col sm="3" class="pr-1">
                  <b-form-input placeholder="Капча" :state="Boolean(newReply.captcha)" v-model="newReply.captcha" size="sm"></b-form-input>
                </b-col>
                <b-col sm="2" class="pl-1">
                  <b-img :src="newReply.captchaPic" style="width: 100%; height: 32px;border-radius: 3.2px;" fluid alt="Captcha"></b-img>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="2">
              <b-button variant="dark" type="submit" size="sm" block>Отправить</b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <b-alert v-else class="mb-2 text-center" show variant="danger">
        <b>Обращение закрыто. Для коммуникации со службой поддержки создайте новый тикет.</b>
      </b-alert>
      <hr class="mt-1 mb-2"/>
      <b-alert :variant="!reply.by_user ? 'secondary':'primary'" class="mb-2 mt-1" show v-for="reply in  [].concat(replies.items).reverse()" :key="reply.id">
        <b-row>
          <b-col>
            <b>Сообщение от {{ reply.by_user ? 'Вас' : 'поддержки' }}</b>
          </b-col>
          <b-col class="text-right">
            <b>{{ reply.created_at }}</b>
          </b-col>
        </b-row>
        {{ reply.message }}
      </b-alert>
    </b-modal>
    <b-modal title="Создать новое обращение" id="new-ticket-modal" hide-footer size="md">
      <b-overlay no-wrap :show="newTicket.state == dataStates.LOADING">
      </b-overlay>
      <b-form @submit.prevent="onNewTicketFormSubmit">
        <b-form-group label="Заголовок">
          <b-form-input size="sm" v-model="newTicket.subject" :state="Boolean(newTicket.subject)" placeholder="Заголовок обращение, до 30 символов" type="text"></b-form-input>
        </b-form-group>
        <b-form-group class="mb-0" label="Текст">
          <b-form-textarea v-model="newTicket.message" size="sm" :state="Boolean(newTicket.message)" placeholder="Максимально кратко и информативно опишите суть проблемы, до 500 символов" rows="3" max-rows="4"></b-form-textarea>
          <hr class="mb-1 mt-2"/>
          <b-row class="mt-2">
            <b-col>
              <b-row>
                <b-col sm="4" class="pr-1">
                  <b-form-input placeholder="Капча" :state="Boolean(newTicket.captcha)" v-model="newTicket.captcha" size="sm"></b-form-input>
                </b-col>
                <b-col sm="4" class="pl-1">
                  <b-img :src="newTicket.captchaPic" style="width: 100%; height: 32px;border-radius: 3.2px;" fluid alt="Captcha"></b-img>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="3">
              <b-button variant="dark" type="submit" size="sm" block>Отправить</b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'SupportPage',
    data() {
      return {
        newTicket: {
          state: null,
          message: null,
          subject: null,
          captcha: null,
          captchaPic: null
        },
        replies: {
          items: [],
          state: null
        },
        newReply: {
          ticketId: null,
          text: null,
          isTicketClosed: true,
          captcha: null,
          captchaPic: null
        },
        tickets: {
          state: null,
          items: [],
          fields: [
            {
              key: 'subject',
              label: 'Заголовок'
            },
            {
              key: 'is_closed',
              label: 'Статус'
            },
            {
              key: 'created_at',
              label: 'Дата обращения'
            },
            {
              key: 'updated_at',
              label: 'Дата обновления'
            }
          ],
          currentPage: 1,
          totalCount: null,
          itemsPerPage: null
        }
      }
    },
    mounted() {
      this.getTickets()
    },
    watch: {
    },
    methods: {
      getTickets() {
        this.tickets.state = this.dataStates.LOADING
        apiRequest.post('get_tickets', `page=${this.tickets.currentPage}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.tickets.items = response.data['data']['tickets']
            this.tickets.totalCount = response.data['data']['count']
            this.tickets.itemsPerPage = response.data['data']['per_page']
          }
          this.tickets.state = this.dataStates.LOADED
        })
        .catch(() => {
          this.tickets.state = this.dataStates.ERROR
        })
      },
      getNewReplyPageInfo() {
        apiRequest.get('page/new_ticket_reply')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.newReply.captchaPic = response.data['data']['captcha']
          }
        })
      },
      getNewTicketPageInfo() {
        this.newTicket.state = this.dataStates.LOADING
        apiRequest.get('page/new_ticket')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.newTicket.captchaPic = response.data['data']['captcha']
          }
        })
        .finally(() => {
          this.newTicket.state = this.dataStates.LOADED
        })
      },
      onRefreshButtonClick() {
        this.getTickets()
      },
      onTicketsRowClick(record) {
        if(!this.newReply.captchaPic) {
          this.getNewReplyPageInfo()
        }
        this.$bvModal.show('ticket-replies-modal')
        this.newReply.ticketId = record['id']
        this.getTicketReplies()
      },
      getTicketReplies() {
        this.replies.state = this.dataStates.LOADING
        apiRequest.post('get_replies', `ticket_id=${this.newReply.ticketId}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.replies.items = response.data['data']['replies']
            this.newReply.isTicketClosed = response.data['data']['is_closed'] == 1
          }
          this.replies.state = this.dataStates.LOADED
        })
      },
      onTicketsPageChanged(page) {
        this.replies.currentPage = page
        this.getTickets()
      },
      onNewTicketFormSubmit() {
        let args = {
          'subject': this.newTicket.subject,
          'message': this.newTicket.message,
          'captcha': this.newTicket.captcha
        }
        let data = this.serializeParams(args)
        this.newTicket.state = this.dataStates.LOADING
        apiRequest.post('new_ticket', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.$bvModal.hide('new-ticket-modal')
            this.newTicket.subject = null
            this.newTicket.message = null
            this.newTicket.captcha = null
            this.getTickets()
          } else {
            this.showAlert(false, response.data['message'])
            this.newTicket.captcha = null
            if(response.data['data'] != null && 'captcha' in response.data['data']) {
              this.newTicket.captchaPic = response.data['data']['captcha']
            }
          }
        })
        .finally(() => {
          this.newTicket.state = this.dataStates.LOADED
        })
      },
      onCloseTicketButtonClick() {
        this.$bvModal.msgBoxConfirm('Закрыть обращение?', {
          title: 'Подтверждение',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Да',
          cancelVariant: 'dark',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            this.replies.state = this.dataStates.LOADING
            apiRequest.post('close_ticket', `ticket_id=${this.newReply.ticketId}`)
            .then(response => {
              if(response.data['status'] == 'success') {
                this.getTicketReplies()
                this.getTickets()
              } else {
                this.replies.state = this.dataStates.LOADED
                this.showAlert(false, response.data['message'])
              }
            })
          }
        })
      },
      onAddButtonClick() {
        this.getNewTicketPageInfo()
        this.$bvModal.show('new-ticket-modal')
      },
      onNewReplyFormSubmit() {
        let args = {
          'ticket_id': this.newReply.ticketId,
          'message': this.newReply.text,
          'captcha': this.newReply.captcha
        }
        let data = this.serializeParams(args)
        this.replies.state = this.dataStates.LOADING
        apiRequest.post('new_reply', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.getTicketReplies()
            this.newReply.text = null
          } else {
            this.replies.state = this.dataStates.LOADED
            this.showAlert(false, response.data['message'])
          }
          if(response.data['data'] != null && 'captcha' in response.data['data']) {
            this.newReply.captchaPic = response.data['data']['captcha']
          }
          this.newReply.captcha = null
        })
      }
    }
  }
</script>
