<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12" lg="6" class="mb-3">
        <b-card title="Ваш профиль" class="mb-3">
          <b-overlay :show="profile.state == dataStates.LOADING">
            <b-table-simple outlined small responsive class="mb-md-3 mb-sm-0">
              <b-tr>
                <b-th class="text-right">Имя пользователя</b-th>
                <b-td><span v-text="profile.userName"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Электронная почта</b-th>
                <b-td><span v-text="profile.email || '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Дата регистрации</b-th>
                <b-td><span v-text="profile.createdAt"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Дата активации</b-th>
                <b-td><span v-text="profile.activatedAt || '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Доход за текущий месяц</b-th>
                <b-td><span v-text="profile.incomeMonth ? `${parseFloat(profile.incomeMonth).toFixed(3)} USDT` : '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Плата за текущий месяц</b-th>
                <b-td><span v-text="profile.sysPercent ? `${parseFloat(profile.sysPercent).toFixed(3)} USDT` : '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Доход за прошедший месяц</b-th>
                <b-td><span v-text="profile.incomePrevMonth ? `${parseFloat(profile.incomePrevMonth).toFixed(3)} USDT` : '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Плата за прошедший месяц</b-th>
                <b-td><span v-text="profile.sysPercentPrev ? `${parseFloat(profile.sysPercentPrev).toFixed(3)} USDT` : '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Текущий тарифный план</b-th>
                <b-td><span v-text="profile.rests"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Проплачено до</b-th>
                <b-td><span v-text="profile.periodEndsAt || '-'"></span></b-td>
              </b-tr>
              <b-tr>
                <b-th class="text-right">Пригласил</b-th>
                <b-td><span v-text="profile.referredBy || '-'"></span></b-td>
              </b-tr>
            </b-table-simple>
            <b-row class="justify-content-between">
              <b-col sm="12" md="12" lg="12" xl="4">
                <b-button block variant="danger" class="mt-sm-2 mb-md-2 mb-lg-0 mt-md-0" size="sm" v-if="profile.isSubPaymentExists" v-b-modal.pay-sub-modal>Абонентская плата</b-button>
              </b-col>
              <b-col sm="12" md="12" lg="12" xl="8">
                <b-row>
                  <b-col md="3" :class="['pr-md-1', 'mt-sm-2', 'mt-md-0', Boolean(profile.periodEndsAt) ? '' : 'invisible']">
                    <b-button block variant="dark" size="sm" class="mr-1" v-b-modal.put-payment-modal>Платёж</b-button>
                  </b-col>
                  <b-col class="pr-md-1 pl-md-1 mt-sm-2 mt-md-0" md="4">
                    <b-button block variant="dark" size="sm" v-b-modal.change-email-modal>Сменить почту</b-button>
                  </b-col>
                  <b-col class="pl-md-1 mt-sm-2 mt-md-0" md="5">
                    <b-button block variant="dark" size="sm" v-b-modal.change-password-modal>Сменить пароль</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
        <b-card title="Телеграм уведомления">
          <b-overlay :show="profile.state == dataStates.LOADING || notificationsButtonsState">
            <template v-if="!profile.isBotConnected">
              <b-alert show variant="primary">
                Уведомления о работе бота всегда позволяют держать Вас в курсе событий. Для подключения уведомлений используйте кнопку ниже.
              </b-alert>
              <div class="text-right mt-3">
                <b-button variant="dark" size="sm" @click="onConnectTgButtonClick">Подключить</b-button>
              </div>
            </template>
            <template v-else>
              <b-alert show variant="success">
                Уведомления о работе бота всегда позволяют держать Вас в курсе событий. Уведомления подключены<span v-html="profile.isAlertsMuted ? ', но <b>заглушены</b>' : ''"></span>.
              </b-alert>
              <div class="text-right mt-3">
                <b-button-group>
                  <b-button variant="dark" size="sm" @click="onMuteBotButtonClick">{{profile.isAlertsMuted ? 'Включить' : 'Заглушить'}} уведомления</b-button>
                  <b-button variant="dark" size="sm" @click="onDisconnectTgButtonClick">Отвязать аккаунт</b-button>
                </b-button-group>
              </div>
            </template>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="12" lg="6" class="mb-3">
        <b-card title="Ордера в холдинге" class="mb-3">
          <b-overlay no-wrap :show="profile.state == dataStates.LOADING">
          </b-overlay>
          <b-table head-variant="light" hover borderless outlined responsive class="text-center table-custom" small  :items="holdingOrders.items" :fields="holdingOrders.fields" show-empty :emptyText="emptyTableText">
            <template #cell(income)="data">
              {{ parseFloat(data.value || '0').toFixed(2) }} USDT
            </template>
            <template #empty="scope">
              <center>
                {{ scope.emptyText }}
              </center>
            </template>
          </b-table>
        </b-card>
        <b-card title="История платежей">
          <b-overlay no-wrap :show="profile.state == dataStates.LOADING"></b-overlay>
          <b-table head-variant="light" id="payments-table" hover borderless outlined responsive class="text-center table-custom" small  :items="payments.items" :fields="payments.fields" show-empty :emptyText="emptyTableText">
            <template #empty="scope">
              <center>
                {{ scope.emptyText }}
              </center>
            </template>
            <template #cell(status)="data">
              {{ getInvoiceStatusStr(data.value) }}
            </template>
            <template #cell(amount_usd)="data">
              ${{ data.value.toFixed(2) }}
            </template>
            <template #cell(type)="data">
              {{ getPaymentTypeStr(data.value) }}
            </template>
          </b-table>
          <b-pagination @input="onPaymentsPageChanged" size="sm" align="right" v-model="payments.currentPage" :total-rows="payments.totalCount" :per-page="payments.itemsPerPage" aria-controls="payments-table"></b-pagination>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="telegram-notifications-modal" hide-footer title="Подключение уведомлений">
      <b-overlay :show="connectAlerts.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-jumbotron class="pt-2 pb-2 mb-2">
        Для подключения уведомлений в телеграм отправьте боту <a target="_blank" :href="`https://t.me/${connectAlerts.botLink}`">https://t.me/{{ connectAlerts.botLink }}</a> код <b style="user-select: all;">{{ connectAlerts.code }}</b>.
        После этого нажмите кнопку "готово".
      </b-jumbotron>
      <div class="text-right">
        <b-button size="sm" @click="onCodeReadyButtonClick" variant="dark">Готово</b-button>
      </div>
    </b-modal>
    <b-modal size="md" id="change-email-modal" hide-footer title="Смена почты">
      <b-overlay :show="changeEmailForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form @submit.prevent="onChangeEmailFormSubmit">
        <b-alert :show="Boolean(changeEmailForm.lastMessage)" variant="success">
          {{ changeEmailForm.lastMessage }}
        </b-alert>
        <b-form-group class="mb-0">
          <b-input-group size="sm">
            <b-form-input type="text" v-if="changeEmailForm.step == 1" size="sm" v-model="changeEmailForm.newEmail" placeholder="Новый адрес почты"></b-form-input>
            <b-form-input type="text" v-else size="sm" v-model="changeEmailForm.code" placeholder="Код подтверждения"></b-form-input>
            <template #append>
              <b-button type="submit" variant="dark">Далее</b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal size="sm" id="change-password-modal" hide-footer title="Сменить пароль">
      <b-overlay :show="changePasswordForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form @submit.prevent="onChangePasswordFormSubmit">
        <b-form-group label="Старый пароль">
          <b-form-input :readonly="isChangePasswordFormSubmitting" size="sm" type="password" v-model="changePasswordForm.oldPass" placeholder="Старый пароль"></b-form-input>
        </b-form-group>
        <b-form-group label="Новый пароль">
          <b-form-input :readonly="isChangePasswordFormSubmitting" size="sm" type="password" v-model="changePasswordForm.newPass" placeholder="Новый пароль"></b-form-input>
        </b-form-group>
        <b-form-group label="Повторите пароль">
          <b-form-input :readonly="isChangePasswordFormSubmitting" size="sm" type="password" v-model="changePasswordForm.rePass" placeholder="Повторите пароль"></b-form-input>
        </b-form-group>
        <b-row class="justify-content-end">
          <b-col sm="5" class="text-right">
            <b-button :disabled="isChangePasswordFormSubmitting" block type="submit"  variant="dark" size="sm">
              <b-spinner v-if="isChangePasswordFormSubmitting" small type="grow"></b-spinner>
              <span v-else>Далее</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal id="pay-sub-modal" @show="onPaySubModalOpen" @hide="onPaySubModalClose" hide-footer title="Внести платёж">
      <b-overlay no-wrap :show="subPaymentModal.state == dataStates.LOADING">>
      </b-overlay>
      <template v-if="subPaymentModal.invoice.address != null">
        <template v-if="subPaymentModal.invoice.timeLeft <= 1 || subPaymentModal.invoice.state == 'failed'">
          Время действия ранее созданного платежа истекло. Выберите действие, используя кнопки ниже.<br/><br/>Если Вы произвели оплату после истечения срока жизни платежа, обратитесь в поддержку.
          <div class="text-right mt-2">
            <b-button variant="dark" class="mr-1" @click="onSubReissueButtonClick" type="button" size="sm">Пересоздать</b-button>
            <b-button variant="dark" @click="onSubPaymentCancelButtonClick" type="button" size="sm">Отменить</b-button>
          </div>
        </template>
        <template v-else>
          <span>Для осуществления оплаты необходимо внести на указанный адрес <b>{{ subPaymentModal.invoice.paymentType }}</b> точную сумму монет, указанную ниже. Сообщать об оплате нет необходимости.</span>
          <b-row class="mt-3 mb-2">
            <b-col>
              <b-form-group class="mb-0" label-class="text-muted" label="Адрес оплаты">
                <b-input-group size="sm">
                  <b-input-group-append>
                    <b-button v-b-tooltip.hover title="Скопировать" @click="copyText(subPaymentModal.invoice.address)" variant="dark"><b-icon icon="textarea-t"></b-icon></b-button>
                  </b-input-group-append>
                  <b-form-input class="white-bg" v-model="subPaymentModal.invoice.address" readonly></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="4" class="text-right">
              <b-form-group class="mb-0" label-class="text-muted" label="Сумма оплаты">
                <b-input-group size="sm">
                  <b-form-input size="sm" class="white-bg text-right" v-model="subPaymentModal.invoice.amount" readonly></b-form-input>
                  <b-input-group-prepend>
                    <b-button v-b-tooltip.hover title="Скопировать" @click="copyText(subPaymentModal.invoice.amount)" variant="dark"><b-icon icon="textarea-t"></b-icon></b-button>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-center" style="line-height: 1">
            <small><i>Статус оплаты:</i> {{ getInvoiceStatusStr(this.subPaymentModal.invoice.status).toLowerCase() }}<br/><i>На оплату осталось:</i> {{ timeLeftForHumanSub }}</small>
          </div>
          <div class="text-right mt-2">
            <b-button variant="danger" :disabled="isInvoiceDisabled" @click="onSubPaymentCancelButtonClick" type="button" size="sm">Отменить</b-button>
          </div>
        </template>
      </template>
      <template v-else>
        <b-form-group label="Выберите тип оплаты">
          <b-form-select size="sm" text-field="title" value-field="id" v-model="subPaymentModal.paymentType" :options="paymentModal.paymentTypes"></b-form-select>
        </b-form-group>
        <div class="text-right">
          <b-button variant="dark" type="submit" @click="onSubPaymentTypeButtonClick" size="sm">Далее</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="put-payment-modal" @show="onPutPaymentModalOpen" @hide="onPutPaymentModalClose" hide-footer title="Внести платёж">
      <b-overlay no-wrap :show="paymentModal.state == dataStates.LOADING">>
      </b-overlay>
      <template v-if="paymentModal.invoice.address === null">
        <template v-if="paymentModal.selectedAct === null">
          <b-form-group label="Выберите действие">
            <b-form-select size="sm" v-model="paymentModal.act" :options="paymentModal.actOptions"></b-form-select>
          </b-form-group>
          <div class="text-right">
            <b-button variant="dark" @click="onSelectActionButtonClick" type="submit" size="sm">Далее</b-button>
          </div>
        </template>
        <template v-else>
          <template v-if="paymentModal.selectedPaymentType === null">
            <b-form-group label="Выберите тип оплаты">
              <b-form-select size="sm" text-field="title" value-field="id" v-model="paymentModal.paymentType" :options="paymentModal.paymentTypes"></b-form-select>
            </b-form-group>
            <div class="text-right">
              <b-button variant="dark" class="mr-1" @click="onPaymentBackButtonClick" type="button" size="sm">Назад</b-button>
              <b-button variant="dark" type="submit" @click="onPaymentTypeButtonClick" size="sm">Далее</b-button>
            </div>
          </template>
          <template v-else>
            <template v-if="paymentModal.act == 0">
              <div class="mb-3">
                Текущий тариф: {{ profile.rests }}<br/>
                Оплачено до: {{ profile.periodEndsAt }}
              </div>
              <b-alert v-if="paymentModal.plans.length == 0" show class="p-3" variant="danger">
                <b>Невозможно произвести изменения, так как в настоящий момент у вас максимальный тарифный план.</b>
              </b-alert>
              <b-form-group v-else label="Выберите тариф">
                <b-form-select size="sm" text-field="title" value-field="id" v-model="paymentModal.plan" :options="paymentModal.plans"></b-form-select>
              </b-form-group>
              <div class="text-right">
                <b-button variant="dark" class="mr-1" @click="onPaymentBackButtonClick" type="button" size="sm">Назад</b-button>
                <b-button variant="dark" :disabled="paymentModal.plans.length == 0" type="submit" @click="onSelectPlanButtonClick" size="sm">Далее</b-button>
              </div>
            </template>
            <template v-if="paymentModal.act == 1">
              <div class="mb-3">
                Текущий тариф: {{ profile.rests }}<br/>
                Оплачено до: {{ profile.periodEndsAt }}
              </div>
              <b-row>
                <b-col class="pr-1">
                  <b-form-group label="Выберите тариф">
                    <b-form-select size="sm" text-field="title" value-field="id" v-model="paymentModal.plan" :options="paymentModal.plans"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="pl-1">
                  <b-form-group label="Выберите период">
                    <b-form-select size="sm" v-model="paymentModal.period" :options="paymentModal.periods"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="text-right">
                <b-button variant="dark" class="mr-1" @click="onPaymentBackButtonClick" type="button" size="sm">Назад</b-button>
                <b-button variant="dark" type="submit" @click="onSelectPlanAndPeriodButtonClick" size="sm">Далее</b-button>
              </div>
            </template>
          </template>
        </template>
      </template>
      <template v-else>
        <template v-if="paymentModal.invoice.timeLeft <= 1 || paymentModal.invoice.state == 'failed'">
          Время действия ранее созданного платежа истекло. Выберите действие, используя кнопки ниже.<br/><br/>Если Вы произвели оплату после истечения срока жизни платежа, обратитесь в поддержку.
          <div class="text-right mt-2">
            <b-button variant="dark" class="mr-1" @click="onReissueButtonClick" type="button" size="sm">Пересоздать</b-button>
            <b-button variant="dark" @click="onPaymentCancelButtonClick" type="button" size="sm">Отменить</b-button>
          </div>
        </template>
        <template v-else>
          <span>Для осуществления оплаты необходимо внести на указанный адрес <b>{{ paymentModal.invoice.paymentType }}</b> точную сумму монет, указанную ниже. Сообщать об оплате нет необходимости.</span>
          <b-row class="mt-3 mb-2">
            <b-col class="pr-1">
              <b-form-group class="mb-0" label-class="text-muted" label="Адрес оплаты">
                <b-input-group size="sm">
                  <b-input-group-append>
                    <b-button v-b-tooltip.hover title="Скопировать" @click="copyText(paymentModal.invoice.address)" variant="dark"><b-icon icon="textarea-t"></b-icon></b-button>
                  </b-input-group-append>
                  <b-form-input class="white-bg" v-model="paymentModal.invoice.address" readonly></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col sm="4" class="pl-1 text-right">
              <b-form-group class="mb-0" label-class="text-muted" label="Сумма оплаты">
                <b-input-group size="sm">
                  <b-form-input size="sm" class="white-bg text-right" v-model="paymentModal.invoice.amount" readonly></b-form-input>
                  <b-input-group-prepend>
                    <b-button v-b-tooltip.hover title="Скопировать" @click="copyText(paymentModal.invoice.amount)" variant="dark"><b-icon icon="textarea-t"></b-icon></b-button>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-center" style="line-height: 1">
            <small><i>Статус оплаты:</i> {{ getInvoiceStatusStr(this.paymentModal.invoice.status).toLowerCase() }}<br/><i>На оплату осталось:</i> {{ timeLeftForHuman }}</small>
          </div>
          <div class="text-right mt-2">
            <b-button variant="danger" :disabled="isInvoiceDisabled" @click="onPaymentCancelButtonClick" type="button" size="sm">Отменить</b-button>
          </div>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'
  import Constants from '../constants.js'

  export default {
    name: 'DashboardPage',
    data() {
      return {
        notificationsButtonsState: false,
        holdingOrders: {
          fields: [
            {
              label: 'Биржа',
              key: 'exchange',
              sortable: true
            },
            {
              label: 'Валютная пара',
              key: 'asset',
              sortable: true
            },
            {
              label: 'Цена',
              key: 'price',
              sortable: true
            },
            {
              label: 'Объём',
              key: 'size',
              sortable: true
            },
          ],
          items: []
        },
        updateTimeLeftTimer: null,
        updateInvoiceTimer: null,
        updateSubInvoiceTimer: null,
        subPaymentModal: {
          state: null,
          paymentTypes: [],
          paymentType: null,
          invoice: {
            timeLeft: null,
            status: null,
            address: null,
            paymentType: null,
            amount: null,
            amountUsd: null
          },
        },
        paymentModal: {
          paymentTypes: [],
          periods: [
            { text: '1 месяц', value: 1 },
            { text: '2 месяца', value: 2 },
            { text: '3 месяца', value: 3 },
          ],
          period: 1,
          paymentType: null,
          actOptions: [
            { text: 'Изменить тариф на текущий абонентский период', value: 0 },
            { text: 'Оплатить обслуживание за следующий период', value: 1 },
          ],
          act: 0,
          selectedPeriod: null,
          selectedPaymentType: null,
          selectedPlan: null,
          selectedAct: null,
          invoice: {
            timeLeft: null,
            status: null,
            address: null,
            paymentType: null,
            amount: null,
            amountUsd: null
          },
          state: null
        },
        changeEmailForm: {
          state: null,
          step: 1,
          code: null,
          newEmail: null,
          lastMessage: null
        },
        changePasswordForm: {
          oldPass: null,
          newPass: null,
          rePass: null,
          state: null
        },
        payments: {
          itemsPerPage: 15,
          currentPage: 1,
          totalCount: null,
          items: [],
          fields: [
            {
              key: 'created_at',
              label: 'Дата',
            },
            {
              key: 'type',
              label: 'Тип',
            },
            {
              key: 'amount_usd',
              label: 'Сумма',
            },
            {
              key: 'status',
              label: 'Статус',
            },
          ]
        },
        profile: {
          state: null,
          email: null,
          rests: null,
          periodEndsAt: null,
          userName: null,
          createdAt: null,
          activatedAt: null,
          sysPercent: null,
          isBotConnected: null,
          isAlertsMuted: null,
          referredBy: null,
          isSubPaymentExists: false
        },
        connectAlerts: {
          state: null,
          botLink: null,
          code: null
        }
      }
    },
    mounted() {
      this.getUserPayments()
      this.getProfileData()
      if(this.updateTimeLeftTimer) {
        clearInterval(this.updateTimeLeftTimer)
      }
      this.updateTimeLeftTimer = setInterval(this.updateTimeLeft, 1000)
    },
    beforeDestroy() {
      clearInterval(this.updateTimeLeftTimer)
      if(this.updateInvoiceTimer) {
        clearInterval(this.updateInvoiceTimer)
      }
      if(this.updateSubInvoiceTimer) {
        clearInterval(this.updateSubInvoiceTimer)
      }
    },
    computed: {
      timeLeftForHuman() {
        let res = new Date(this.paymentModal.invoice.timeLeft * 1000).toISOString().substr(11, 8)
        return res
      },
      timeLeftForHumanSub() {
        let res = new Date(this.subPaymentModal.invoice.timeLeft * 1000).toISOString().substr(11, 8)
        return res
      },
      isChangePasswordFormSubmitting() {
        return this.changePasswordForm.state == this.dataStates.LOADING
      },
      isInvoiceDisabled() {
        return ['unconfirmed', 'done', 'insufficient_funds'].includes(this.paymentModal.invoice.status)
      }
    },
    watch: {
    },
    methods: {
      getInvoiceStatusStr(name) {
        switch(name) {
          case 'ready':
            return 'Сформировано'
          case 'unconfirmed':
            return 'Ожидаем подтверждения'
          case 'done':
            return 'Выполнено'
          case 'insufficient_funds':
            return 'Ожидаем средства'
          case 'failed':
            return 'Истекло время'
          case 'canceled':
            return 'Отменено'
        }
        return '-'
      },
      updateTimeLeft() {
        if(this.paymentModal.invoice.timeLeft !== null) {
          this.paymentModal.invoice.timeLeft--;
        }
        if(this.subPaymentModal.invoice.timeLeft !== null) {
          this.subPaymentModal.invoice.timeLeft--;
        }
      },
      onDisconnectTgButtonClick() {
        this.$bvModal.msgBoxConfirm('После совершения этого действия от вашего телеграм аккаунта будут отключены уведомления. Вы уверены?', {
          title: 'Отвязать аккаунт',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Отмена',
          cancelVariant: 'dark',
          footerClass: 'p-2',
          hideHeaderClose: false,
        })
        .then(value => {
          if(value) {
            this.profile.state = this.dataStates.LOADING
            apiRequest.get('disconnect_alerts')
            .then(response => {
              if(response.data['status'] == 'success') {
                this.showAlert(true, response.data['message'])
                this.getProfileData()
              }
            })
          }
        })
      },
      onPutPaymentModalClose() {
        if(this.updateInvoiceTimer) {
          clearInterval(this.updateInvoiceTimer)
        }
      },
      onPutPaymentModalOpen() {
        this.getInvoiceInfo(false, () => {
          if(this.paymentModal.invoice.address) {
            if(this.updateInvoiceTimer) {
              clearInterval(this.updateInvoiceTimer)
            }
            this.updateInvoiceTimer = setInterval(() => { this.getInvoiceInfo(true) }, 10000)
          }
        })
      },
      getInvoiceInfo(closeIfDone=false, doneCallback=null) {
        this.paymentModal.state = this.dataStates.LOADING
        apiRequest.post('get_active_invoice', 'type=tech')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentModal.invoice.address = response.data['data']['address']
            this.paymentModal.invoice.status = response.data['data']['status']
            this.paymentModal.invoice.amount = response.data['data']['amount']
            this.paymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.paymentModal.invoice.paymentType = response.data['data']['payment_type']
            if(this.paymentModal.invoice.state == 'done') {
              this.paymentModal.invoice.timeLeft = 2
              if(closeIfDone) {
                this.$bvModal.hide('put-payment-modal')
                this.paymentModal.selectedPaymentType = null
                this.paymentModal.selectedPlan = null
                this.paymentModal.selectedAct = null
                this.getProfileData()
              }
            } else {
              this.paymentModal.invoice.timeLeft = response.data['data']['time_left']
            }
          } else {
            this.NullPaymentData()
            if(closeIfDone) {
              this.$bvModal.hide('put-payment-modal')
              this.getProfileData()
            }
          }
        })
        .finally(() => {
          if(doneCallback) {
            doneCallback()
          }
          this.paymentModal.state = this.dataStates.LOADED
          this.getUserPayments()
        })
      },
      getSubInvoiceInfo(closeIfDone=false, doneCallback=null) {
        this.subPaymentModal.state = this.dataStates.LOADING
        apiRequest.post('get_active_invoice', 'type=subscription')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.subPaymentModal.invoice.address = response.data['data']['address']
            this.subPaymentModal.invoice.status = response.data['data']['status']
            this.subPaymentModal.invoice.amount = response.data['data']['amount']
            this.subPaymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.subPaymentModal.invoice.paymentType = response.data['data']['payment_type']
            if(this.subPaymentModal.invoice.state == 'done') {
              this.subPaymentModal.invoice.timeLeft = 2
              if(closeIfDone) {
                this.$bvModal.hide('pay-sub-modal')
                this.subPaymentModal.selectedPaymentType = null
                this.subPaymentModal.selectedPlan = null
                this.subPaymentModal.selectedAct = null
                this.getProfileData()
              }
            } else {
              this.subPaymentModal.invoice.timeLeft = response.data['data']['time_left']
            }
          } else {
            this.NullPaymentData()
            if(closeIfDone) {
              this.$bvModal.hide('pay-sub-modal')
              this.getProfileData()
            }
          }
        })
        .finally(() => {
          if(doneCallback) {
            doneCallback()
          }
          this.subPaymentModal.state = this.dataStates.LOADED
          this.getUserPayments()
        })
      },
      onPaymentsPageChanged(value) {
        this.payments.currentPage = value
        this.getUserPayments()
      },
      onPaymentCancelButtonClick() {
        this.$bvModal.msgBoxConfirm('Вы уверены что хотите отменить оплату?', {
          title: 'Подтверждение',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'dark',
          footerClass: 'p-2',
          hideHeaderClose: false,
        })
        .then(value => {
          if(value) {
            this.paymentModal.state = this.dataStates.LOADING
            apiRequest.post('cancel_invoice', 'type=tech')
            .then(response => {
              if(response.data['status'] == 'success') {
                this.NullPaymentData()
                this.$bvModal.hide('put-payment-modal')
              }
            })
            .finally(() => {
              this.paymentModal.state = this.dataStates.LOADED
            })
          }
        })
      },
      NullPaymentData() {
        this.paymentModal.selectedPaymentType = null
        this.paymentModal.selectedPlan = null
        this.paymentModal.selectedAct = null
        this.paymentModal.invoice.address = null
        this.paymentModal.invoice.timeLeft = null
        this.paymentModal.invoice.status = null
        this.paymentModal.invoice.amount = null
        this.paymentModal.invoice.amountUsd = null
        this.paymentModal.invoice.paymentType = null
      },
      onSubPaymentCancelButtonClick() {
        this.$bvModal.msgBoxConfirm('Вы уверены что хотите отменить оплату?', {
          title: 'Подтверждение',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'dark',
          footerClass: 'p-2',
          hideHeaderClose: false,
        })
        .then(value => {
          if(value) {
            this.subPaymentModal.state = this.dataStates.LOADING
            apiRequest.post('cancel_invoice', 'type=subscription')
            .then(response => {
              if(response.data['status'] == 'success') {
                this.subPaymentModal.selectedPaymentType = null
                this.subPaymentModal.selectedPlan = null
                this.subPaymentModal.selectedAct = null
                this.subPaymentModal.invoice.address = null
                this.subPaymentModal.invoice.timeLeft = null
                this.subPaymentModal.invoice.status = null
                this.subPaymentModal.invoice.amount = null
                this.subPaymentModal.invoice.amountUsd = null
                this.subPaymentModal.invoice.paymentType = null
                this.$bvModal.hide('pay-sub-modal')
              }
            })
            .finally(() => {
              this.subPaymentModal.state = this.dataStates.LOADED
            })
          }
        })
      },
      onSubReissueButtonClick() {
        this.subPaymentModal.state = this.dataStates.LOADING
        apiRequest.post('reissue_payment', 'type=subscription')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.subPaymentModal.invoice.address = response.data['data']['address']
            this.subPaymentModal.invoice.timeLeft = response.data['data']['time_left']
            this.subPaymentModal.invoice.status = response.data['data']['status']
            this.subPaymentModal.invoice.amount = response.data['data']['amount']
            this.subPaymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.subPaymentModal.invoice.paymentType = response.data['data']['payment_type']
          }
        })
        .finally(() => {
          this.subPaymentModal.state = this.dataStates.LOADED
        })
      },
      onChangeEmailFormSubmit() {
        this.changeEmailForm.state = this.dataStates.LOADING
        if(this.changeEmailForm.step == 1) {
          apiRequest.post('send_new_email_code', `new_email=${this.changeEmailForm.newEmail}`)
          .then(response => {
            if(response.data['status'] == 'success') {
              if(response.data['data']['email_changed']) {
                this.changeEmailForm.step = 3
                this.changeEmailForm.lastMessage = response.data['message']
              } else {
                this.changeEmailForm.step = 2
                this.changeEmailForm.lastMessage = response.data['message']
              }
            } else {
              this.showAlert(false, response.data['message'])
            }
            this.changeEmailForm.state = this.dataStates.LOADED
          })
        } else {
          let args = {
            'new_email': this.changeEmailForm.newEmail,
            'code': this.changeEmailForm.code,
            'step': this.changeEmailForm.step
          }
          let data = this.serializeParams(args)
          apiRequest.post('set_new_email', data)
          .then(response => {
            if(response.data['status'] == 'success') {
              this.changeEmailForm.code = null
              if(this.changeEmailForm.step == 2) {
                this.changeEmailForm.step = 3
                this.changeEmailForm.lastMessage = response.data['message']
              } else if(this.changeEmailForm.step == 3) {
                this.$bvModal.hide('change-email-modal')
                this.getProfileData()
                this.resetChangeEmailForm()
              }
            } else {
              this.showAlert(false, response.data['message'])            
            }
            this.changeEmailForm.state = this.dataStates.LOADED
          })
        }
      },
      resetChangeEmailForm() {
        this.changeEmailForm.step = 1
        this.changeEmailForm.code = null
        this.changeEmailForm.newEmail = null
        this.changeEmailForm.lastMessage = null
      },
      onReissueButtonClick() {
        this.paymentModal.state = this.dataStates.LOADING
        apiRequest.post('reissue_payment', 'type=tech')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentModal.invoice.address = response.data['data']['address']
            this.paymentModal.invoice.timeLeft = response.data['data']['time_left']
            this.paymentModal.invoice.status = response.data['data']['status']
            this.paymentModal.invoice.amount = response.data['data']['amount']
            this.paymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.paymentModal.invoice.paymentType = response.data['data']['payment_type']
          }
        })
        .finally(() => {
          this.paymentModal.state = this.dataStates.LOADED
        })
      },
      onSelectPlanAndPeriodButtonClick() {
        this.paymentModal.selectedPlan = this.paymentModal.plan
        this.paymentModal.selectedPeriod = this.paymentModal.period
        let args = {
          'type': 'tech',
          'plan': this.paymentModal.selectedPlan,
          'period': this.paymentModal.selectedPeriod,
          'method': this.paymentModal.selectedPaymentType
        }
        let data = this.serializeParams(args)
        this.paymentModal.state = this.dataStates.LOADING
        apiRequest.post('put_payment', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentModal.invoice.address = response.data['data']['address']
            this.paymentModal.invoice.timeLeft = response.data['data']['time_left']
            this.paymentModal.invoice.status = response.data['data']['status']
            this.paymentModal.invoice.amount = response.data['data']['amount']
            this.paymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.paymentModal.invoice.paymentType = response.data['data']['payment_type']
            if(this.paymentModal.invoice.address) {
              if(this.updateInvoiceTimer) {
                clearInterval(this.updateInvoiceTimer)
              }
              this.updateInvoiceTimer = setInterval(() => { this.getInvoiceInfo(true) }, Constants.UPDATE_INVOICE_INFO_TIME)
            }
          } else {
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.paymentModal.state = this.dataStates.LOADED
        })
      },
      onSelectPlanButtonClick() {
        this.paymentModal.selectedPlan = this.paymentModal.plan
        let args = {
          'type': 'tech_sets',
          'plan': this.paymentModal.selectedPlan,
          'method': this.paymentModal.selectedPaymentType
        }
        let data = this.serializeParams(args)
        this.paymentModal.state = this.dataStates.LOADING
        apiRequest.post('put_payment', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentModal.invoice.address = response.data['data']['address']
            this.paymentModal.invoice.timeLeft = response.data['data']['time_left']
            this.paymentModal.invoice.status = response.data['data']['status']
            this.paymentModal.invoice.amount = response.data['data']['amount']
            this.paymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.paymentModal.invoice.paymentType = response.data['data']['payment_type']
            if(this.paymentModal.invoice.address) {
              if(this.updateInvoiceTimer) {
                clearInterval(this.updateInvoiceTimer)
              }
              this.updateInvoiceTimer = setInterval(() => { this.getInvoiceInfo(true) }, Constants.UPDATE_INVOICE_INFO_TIME)
            }
          } else {
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.paymentModal.state = this.dataStates.LOADED
        })
      },
      onPaymentBackButtonClick() {
        if(this.paymentModal.selectedPlan !== null) {
          this.paymentModal.selectedPlan = null
        } else if(this.paymentModal.selectedPaymentType !== null) {
          this.paymentModal.selectedPaymentType = null
        } else if(this.paymentModal.selectedAct !== null) {
          this.paymentModal.selectedAct = null
        }
      },
      onSelectActionButtonClick() {
        this.paymentModal.selectedAct = this.paymentModal.act
        this.getPaymentTypes()
      },
      onPaymentTypeButtonClick() {
        this.paymentModal.state = this.dataStates.LOADING
        let hide = this.paymentModal.selectedAct == 1 ? 0 : 1
        apiRequest.post('get_rests', `hide=${hide}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentModal.selectedPaymentType = this.paymentModal.paymentType
            this.paymentModal.plans = response.data['data']
            if(this.paymentModal.plans.length > 0) {
              this.paymentModal.plan = this.paymentModal.plans[0]['id']
            }
          }
        })
        .finally(() => {
          this.paymentModal.state = this.dataStates.LOADED
        })
      },
      onMuteBotButtonClick() {
        this.notificationsButtonsState = true
        apiRequest.post('toggle_alerts', `state=${this.profile.isAlertsMuted ? 0 : 1}`)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.profile.isAlertsMuted = !this.profile.isAlertsMuted
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.notificationsButtonsState = false
        })
      },
      onConnectTgButtonClick() {
        this.connectAlerts.state = this.dataStates.LOADING
        this.$bvModal.show('telegram-notifications-modal')
        apiRequest.get('connect_alerts')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.connectAlerts.botLink = response.data['data']['bot']
            this.connectAlerts.code = response.data['data']['code']
          } else {
            this.$bvModal.hide('telegram-notifications-modal')
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.connectAlerts.state = this.dataStates.LOADED
        })
      },
      onCodeReadyButtonClick() {
        this.connectAlerts.state = this.dataStates.LOADING
        apiRequest.get('connect_alerts_done')
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.$bvModal.hide('telegram-notifications-modal')
            this.getProfileData()
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.connectAlerts.state = this.dataStates.LOADED
        })
      },
      getPaymentTypes() {
        this.paymentModal.state = this.dataStates.LOADING
        this.subPaymentModal.state = this.dataStates.LOADING
        apiRequest.get('get_payment_types')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentModal.paymentTypes = response.data['data']
            if(this.paymentModal.paymentType == null && this.paymentModal.paymentTypes.length > 0) {
              this.paymentModal.paymentType = this.paymentModal.paymentTypes[0]['id']
              this.subPaymentModal.paymentType = this.paymentModal.paymentTypes[0]['id']
            }
          }
        })
        .finally(() => {
          this.paymentModal.state = this.dataStates.LOADED
          this.subPaymentModal.state = this.dataStates.LOADED
        })
      },
      getPaymentTypeStr(value) {
        if(value == 'tech') {
          return 'Тех. обслуживание'
        } else if(value == 'subscription') {
          return 'Абонентская плата'
        } else if(value == 'activation') {
          return 'Активация'
        }
        return '-'
      },
      getUserPayments() {
        this.payments.state = this.dataStates.LOADING
        apiRequest.post('get_payments', `page=${this.payments.currentPage}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.payments.items = response.data['data']['payments']
            this.payments.totalCount = response.data['data']['count']
            this.payments.itemsPerPage = response.data['data']['per_page']
          }
        })
        .finally(() => {
          this.payments.state = this.dataStates.LOADED
        })
      },
      getProfileData() {
        this.profile.state = this.dataStates.LOADING
        apiRequest.get('get_profile')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.profile.userName = response.data['data']['username']
            this.profile.email = response.data['data']['email']
            this.profile.rests = response.data['data']['rests']
            this.profile.periodEndsAt = response.data['data']['period_ends_at']
            this.profile.createdAt = response.data['data']['created_at']
            this.profile.activatedAt = response.data['data']['activated_at']
            this.profile.isBotConnected = response.data['data']['is_bot_connected'] == 1
            this.profile.isAlertsMuted = response.data['data']['is_alerts_muted'] == 1
            this.profile.referredBy = response.data['data']['referred_by']
            this.profile.incomeMonth = response.data['data']['total_income_month']
            this.profile.incomePrevMonth = response.data['data']['total_income_prev_month']
            this.profile.sysPercent = response.data['data']['sys_percent']
            this.profile.sysPercentPrev = response.data['data']['sys_percent_prev']
            let subInvoice = response.data['data']['sub_invoice']
            if(subInvoice) {
              this.subPaymentModal.invoice.address = subInvoice['address']
              this.subPaymentModal.invoice.status = subInvoice['status']
              this.subPaymentModal.invoice.amount = subInvoice['amount']
              this.subPaymentModal.invoice.amountUsd = subInvoice['amount_usd']
              this.subPaymentModal.invoice.paymentType = subInvoice['payment_type']
              this.profile.isSubPaymentExists = true
            } else {
              this.profile.isSubPaymentExists = false
            }

            this.holdingOrders.items = response.data['data']['orders']
            this.profile.state = this.dataStates.LOADED
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.profile.state = this.dataStates.ERROR
        })
      },
      onSubPaymentTypeButtonClick() {
        this.subPaymentModal.state = this.dataStates.LOADING
        apiRequest.post('init_sub_payment', `method=${this.subPaymentModal.paymentType}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.subPaymentModal.invoice.address = response.data['data']['address']
            this.subPaymentModal.invoice.status = response.data['data']['status']
            this.subPaymentModal.invoice.timeLeft = response.data['data']['time_left']
            this.subPaymentModal.invoice.amount = response.data['data']['amount']
            this.subPaymentModal.invoice.amountUsd = response.data['data']['amount_usd']
            this.subPaymentModal.invoice.paymentType = response.data['data']['payment_type']
            if(this.updateSubInvoiceTimer) {
              clearInterval(this.updateSubInvoiceTimer)
            }
            this.updateSubInvoiceTimer = setInterval(() => { this.getSubInvoiceInfo(true) }, Constants.UPDATE_INVOICE_INFO_TIME)
          } else {
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.subPaymentModal.state = this.dataStates.LOADED
        })
      },
      onPaySubModalOpen() {
        this.subPaymentModal.state = this.dataStates.LOADING
        apiRequest.post('get_active_invoice', 'type=subscription')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.subPaymentModal.invoice.address = response.data['data']['address']
            if(this.subPaymentModal.invoice.address == null) {
              this.getPaymentTypes()
            } else {
              this.subPaymentModal.invoice.timeLeft = response.data['data']['time_left']
              this.subPaymentModal.invoice.status = response.data['data']['status']
              this.subPaymentModal.invoice.amount = response.data['data']['amount']
              this.subPaymentModal.invoice.amountUsd = response.data['data']['amount_usd']
              this.subPaymentModal.invoice.paymentType = response.data['data']['payment_type']
              if(this.updateSubInvoiceTimer) {
                clearInterval(this.updateSubInvoiceTimer)
              }
              this.updateSubInvoiceTimer = setInterval(() => { this.getSubInvoiceInfo(true) }, Constants.UPDATE_INVOICE_INFO_TIME)
              this.subPaymentModal.state = this.dataStates.LOADED
            }
          }
        })
        .finally(() => {
          this.getUserPayments()
        })
      },
      onPaySubModalClose() {
        if(this.updateSubInvoiceTimer) {
          clearInterval(this.updateSubInvoiceTimer)
        }
      },
      onRefreshButtonClick() {
        this.getProfileData()
        this.getUserPayments()
      },
      onChangePasswordFormSubmit() {
        let args = {
          'old': this.changePasswordForm.oldPass,
          'new': this.changePasswordForm.newPass,
          'rep': this.changePasswordForm.rePass
        }
        let data = this.serializeParams(args)
        this.changePasswordForm.state = this.dataStates.LOADING
        apiRequest.post('change_password', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.$bvModal.hide('change-password-modal')
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.changePasswordForm.state = this.dataStates.LOADED
        })
      }
    }
  }
</script>
