<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-overlay :show="keys.state == dataStates.LOADING">
          <b-card>
            <b-table @row-clicked="onRowClick" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="keys.items" :fields="keys.fields" show-empty stacked="md" :emptyText="emptyTableText">
              <template #empty="scope">
                <center>
                  {{ scope.emptyText }}
                </center>
              </template>
              <template #cell(api_key)="data">
                <span style="filter: blur(0.35rem);">{{ data.value }}</span>
              </template>
              <template #cell(api_secret)="data">
                <span style="filter: blur(0.35rem);">{{ data.value }}</span>
              </template>
              <template #cell(actions)="data">
                <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                  <b-link class="action-label" title="Удалить" @click="onRemoveLabelClick(data.item.id)"><b-icon icon="trash"></b-icon></b-link>
                </div>
              </template>
            </b-table>
            <!--<div class="text-right">
              <b-button v-b-modal.add-key-modal size="sm" variant="dark" class="mr-1"><b-icon icon="plus-circle"></b-icon> Добавить</b-button>
            </div>!-->
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal id="edit-key-modal" size="sm" title="Редактировать API ключ" hide-footer>
      <b-overlay :show="editKeyForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form @submit.prevent="onEditKeyFormSubmit">
        <b-form-group label="Биржа">
          <b-form-select size="sm" v-model="editKeyForm.type" disabled :options="keyTypes"></b-form-select>
        </b-form-group>
        <b-form-group label="Ключ">
          <b-form-input size="sm" type="text" v-model="editKeyForm.key" placeholder="Ключ"></b-form-input>
        </b-form-group>
        <b-form-group label="Секретный ключ">
          <b-form-input size="sm" type="text" v-model="editKeyForm.secret" placeholder="Секретный ключ"></b-form-input>
        </b-form-group>
        <b-form-group label="Пароль" v-if="editKeyForm.isPassphraseRequired">
          <b-form-input size="sm" type="text" v-model="editKeyForm.passphrase" placeholder="Пассфраза"></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button variant="dark" type="submit" size="sm">Сохранить</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal id="add-key-modal" size="sm" title="Добавить API ключ" hide-footer>
      <b-overlay :show="addKeyForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form @submit.prevent="onAddKeyFormSubmit">
        <b-form-group>
          <b-form-select size="sm" v-model="addKeyForm.type" :options="keyTypes"></b-form-select>
        </b-form-group>
        <b-form-group>
          <b-form-input size="sm" type="text" v-model="addKeyForm.key" placeholder="Ключ"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input size="sm" type="text" v-model="addKeyForm.secret" placeholder="Секретный ключ"></b-form-input>
        </b-form-group>
        <b-form-group v-if="addKeyForm.isPassphraseRequired">
          <b-form-input size="sm" type="text" v-model="addKeyForm.passphrase" placeholder="Пассфраза"></b-form-input>
        </b-form-group>
        <div class="text-right">
          <b-button variant="dark" type="submit" size="sm">Добавить</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'ApiKeysPage',
    data() {
      return {
        keyTypes: [],
        addKeyForm: {
          state: null,
          type: null,
          key: null,
          secret: null,
          passphrase: null,
          isPassphraseRequired: false
        },
        editKeyForm: {
          id: null,
          type: null,
          key: null,
          secret: null,
          passphrase: null,
          isPassphraseRequired: false,
          state: null
        },
        keys: {
          state: null,
          items: [],
          fields: [
            {
              key: 'key_type',
              label: 'Биржа',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              key: 'api_key',
              label: 'Ключ',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              key: 'api_secret',
              label: 'Секретный ключ',
              sortable: true,
              tdClass: 'clickable'
            },
            {
              key: 'actions',
              label: 'Действия',
              sortable: true
            }
          ]
        }
      }
    },
    mounted() {
      this.keys.state = this.dataStates.EMPTY
      this.getApiKeys()
      this.getKeyTypes()
    },
    watch: {
      'addKeyForm.type': function(value) {
        this.keyTypes.every(el => {
          if(el['value'] == value) {
            this.addKeyForm.isPassphraseRequired = el['is_passphrase_required']
            return false
          }
          return true
        })
      },
      'editKeyForm.type': function(value) {
        this.keyTypes.every(el => {
          if(el['value'] == value) {
            this.editKeyForm.isPassphraseRequired = el['is_passphrase_required']
            return false
          }
          return true
        })
      },
      'keyTypes': function(value) {
        if(value.length == 0) {
          let itm = {
            'value': null,
            'text': 'Биржи недоступны',
            'disabled': true
          }
          value.unshift(itm)
        } else if(value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Выберите биржу',
            'disabled': true
          }
          value.unshift(itm)
        }
      }
    },
    methods: {
      onRowClick(item) {
        this.onEditLabelClick(item.id)
      },
      hideString(input) {
        let results = input.substr(0, 1)
        let inputLength = input.length
        for(let x = 0;x<inputLength-4;x++) {
          results += '●'
        }
        results += input.substr(inputLength-1)
        return results
      },
      onEditKeyFormSubmit() {
        let args = {
          'id': this.editKeyForm.id,
          'key': this.editKeyForm.key,
          'secret': this.editKeyForm.secret,
          'passphrase': this.editKeyForm.passphrase,
        }
        let data = this.serializeParams(args)
        this.editKeyForm.state = this.dataStates.LOADING
        apiRequest.post('edit_api_key', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getApiKeys()
            this.$bvModal.hide('add-key-modal')
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.editKeyForm.state = this.dataStates.LOADED
        })
      },
      onRefreshButtonClick() {
        this.getApiKeys()
      },
      onAddKeyFormSubmit() {
        let args = {
          'type': this.addKeyForm.type,
          'key': this.addKeyForm.key,
          'secret': this.addKeyForm.secret,
          'passphrase': this.addKeyForm.passphrase,
        }
        let data = this.serializeParams(args)
        this.addKeyForm.state = this.dataStates.LOADING
        apiRequest.post('add_api_key', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getApiKeys()
            this.$bvModal.hide('add-key-modal')
          }
          this.showAlert(success, response.data['message'])
        }).finally(() => {
          this.addKeyForm.state = this.dataStates.LOADED
        })
      },
      onRemoveLabelClick(keyId) {
        let args = {
          'id': keyId,
        }
        let data = this.serializeParams(args)
        this.keys.state = this.dataStates.LOADING
        apiRequest.post('remove_api_key', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getApiKeys()
          } else {
            this.keys.state = this.dataStates.LOADED
          }
          this.showAlert(success, response.data['message'])
        })
      },
      onAddButtonClick() {
        this.$bvModal.show('add-key-modal')
      },
      getKeyTypes() {
        apiRequest.get('get_key_types')
        .then(response => {
          this.keyTypes = response.data['data']
        })
      },
      getApiKeys() {
        this.keys.state = this.dataStates.LOADING
        apiRequest.get('get_api_keys')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.keys.state = this.dataStates.LOADED
            this.keys.items = response.data['data']
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.keys.state = this.dataStates.ERROR
        })
      },
      onEditLabelClick(keyId) {
        let args = {
          'id': keyId,
        }
        let data = this.serializeParams(args)
        this.$bvModal.show('edit-key-modal')
        this.editKeyForm.state = this.dataStates.LOADING
        apiRequest.post('get_api_key', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.editKeyForm.id = keyId
            this.editKeyForm.type = response.data['data']['key_type_id']
            this.editKeyForm.key = response.data['data']['api_key']
            this.editKeyForm.secret = response.data['data']['api_secret']
            this.editKeyForm.passphrase = response.data['data']['api_passphrase']
            this.editKeyForm.state = this.dataStates.LOADED
          } else {
            if(this.isResponseHasRedirect(response.data)) {
              this.$router.push({ name: 'LoginPage' })
            } else {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.editKeyForm.state = this.dataStates.ERROR
        })
      }
    }
  }
</script>
