<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-card class="p-0" no-body>
          <b-card-body class="p-2">
            <b-row>
              <b-col>
                <b-button size="sm" :disabled="pageState == dataStates.LOADING" @click="onBackButtonClick" variant="dark">Назад</b-button>
              </b-col>
              <!--<b-col cols="7" sm="5" md="4" lg="3" xl="2" class="text-right">
                <b-form-select :disabled="pageState == dataStates.LOADING" size="sm" v-model="currentAsset" :options="assets"></b-form-select>
              </b-col>!-->
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-overlay :show="pageState == dataStates.LOADING">
          <b-card>
            <b-row class="justify-content-center">
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Сегодня: <b>{{ parseFloat(incomes.today || '0').toFixed(3) }} USDT</b>
                </div>
              </b-col>
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Месяц: <b>{{ parseFloat(incomes.month || '0').toFixed(3) }} USDT</b>
                </div>
              </b-col>
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Всего: <b>{{ parseFloat(incomes.totally || '0').toFixed(3) }} USDT</b>
                </div>
              </b-col>
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Депозит: <b>{{ parseFloat(setting.freeDeposit || 0).toFixed(3) || '0' }} USDT</b>
                </div>
              </b-col>
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  В работе: <b>{{ parseFloat(setting.usedDeposit || 0).toFixed(3) || '0' }} USDT</b>
                </div>
              </b-col>
              <!--<b-col v-if="setting.timeLeft != null && setting.timeLeft <= 300" class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  <b>Работает</b>
                </div>
              </b-col>
              <b-col v-else class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block-bad">
                  <b>Не работает</b>
                </div>
              </b-col>!-->
            </b-row>
            <b-form-group>
              <b-form-textarea rows="10" v-model="log" no-resize readonly></b-form-textarea>
            </b-form-group>
            <!--<b-row align-v="center">
              <b-col>
                <b-button-group v-if="!isBotEnabled && isStatusUpdated || isBotEnabled && !isStatusUpdated">
                  <b-button variant="success" :disabled="!isStatusUpdated" size="sm" @click="onStartButtonClick">Старт</b-button>
                </b-button-group>
                <b-button-group v-if="!isBotEnabled && !isStatusUpdated">
                  <b-button variant="primary" :disabled="isUrgnetlyAction" size="sm" @click="onCancelActionClick">Отменить действие</b-button>
                </b-button-group>
                <b-button-group v-else-if="isBotEnabled && isStatusUpdated">
                  <b-button variant="danger" size="sm" @click="onStopButtonClick">Стоп</b-button>
                  <b-button variant="info" size="sm" @click="onRestartButtonClick">Перезапустить</b-button>
                  <b-button variant="danger" @click="onExitButtonClick" size="sm">Прервать</b-button>
                </b-button-group>
              </b-col>
              <b-col class="text-right">
                <b-button-group>
                  <b-button @click="onArbButtonClick" :disabled="arbForm.state == dataStates.LOADING" v-if="isArbAvailable" id="arb-button" :variant="arbForm.isArbEnabled ? 'success' : 'danger'" size="sm">Арбитраж</b-button>
                  <b-button variant="success" v-b-modal.holding-orders-modal size="sm">Холдинги</b-button>
                  <b-button variant="success" v-b-modal.settings-modal size="sm">Настройки</b-button>
                  <b-button variant="success" @click="onCleanButtonClick" size="sm">Очистить</b-button>
                </b-button-group>
              </b-col>
            </b-row>!-->
            <div class="float-sm-left">
              <b-button-group class="d-flex" v-if="!isBotEnabled && isStatusUpdated || isBotEnabled && !isStatusUpdated">
                <b-button variant="success" :disabled="!isStatusUpdated" size="sm" @click="onStartButtonClick">Запустить</b-button>
              </b-button-group>
              <b-button-group class="d-flex" v-if="!isBotEnabled && !isStatusUpdated">
                <b-button variant="primary" :disabled="isUrgnetlyAction" size="sm" @click="onCancelActionClick">Отменить действие</b-button>
              </b-button-group>
              <b-button-group class="d-flex" v-else-if="isBotEnabled && isStatusUpdated">
                <b-button variant="danger" size="sm" @click="onStopButtonClick">Стоп</b-button>
                <b-button variant="danger" @click="onExitButtonClick" size="sm">Прервать</b-button>
                <b-button variant="info" size="sm" @click="onRestartButtonClick">Перезапустить</b-button>
              </b-button-group>
            </div>
            <div class="float-sm-right mt-2 mt-sm-0">
              <b-button-group size="sm" class="d-flex">
                <b-button @click="onArbButtonClick" :disabled="arbForm.state == dataStates.LOADING" v-if="isArbAvailable" id="arb-button" :variant="arbForm.isArbEnabled ? 'success' : 'danger'" size="sm">Арбитраж</b-button>
                <b-button variant="success" v-b-modal.holding-orders-modal>Холдинги</b-button>
                <b-button variant="success" v-b-modal.settings-modal>Настройки</b-button>
                <b-button variant="success" title="Очистить журнал" @click="onCleanButtonClick"><b-icon icon="trash"></b-icon></b-button>
              </b-button-group>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-popover :show="arbForm.showPopover" placement="left" target="arb-button">
      <b-overlay :show="arbForm.state == dataStates.LOADING || !arbForm.isUpdated && isBotEnabled" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form-checkbox class="mb-2" :disabled="!arbForm.isUpdated && isBotEnabled" @input="updateArbState(true)" v-model="arbForm.isArbEnabled" switch>Арбитраж</b-form-checkbox>
      <hr class="mb-2 mt-1">
      <b-form-group :label="`Объём холдинга: ${arbForm.hpPart}%`" class="mb-0 mt-0">
        <b-form-input :disabled="!arbForm.isArbEnabled" v-model="arbForm.hpPart" type="range" min="25" max="100"></b-form-input>
      </b-form-group>
      <b-row>
        <b-col class="pr-1">
          <b-button variant="primary" @click="onSaveHpButtonClick" block :disabled="!arbForm.isArbEnabled" size="sm">Сохранить</b-button>
        </b-col>
        <b-col class="pl-1">
          <b-button variant="primary" v-b-modal.calc-modal block size="sm">Калькулятор</b-button>
        </b-col>
      </b-row>
    </b-popover>
    <b-modal id="holding-orders-modal" size="xl" responsive @show="onHoldingOrdersOpen" title="Ордера в холдинге" hide-footer>
      <b-overlay :show="holdingOrders.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-table selectable select-mode="multi" @row-selected="onOrdersRowsSelected" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="holdingOrders.items" :fields="holdingOrders.fields" show-empty :emptyText="emptyTableText">
        <template #empty="scope">
          <center>
            {{ scope.emptyText }}
          </center>
        </template>
        <template #cell(fee)="data">
          {{ parseFloat(data.value || 0).toFixed(3) }}
        </template>
        <template #cell(spent)="data">
          {{ parseFloat(data.value || 0).toFixed(3) }}
        </template>
        <template #cell(is_avg)="data">
          <b-link @click="onCancelAvgOrdersButtonClick(data.item.id)" v-if="data.value > 0">
            <b-badge variant="danger"><b-icon icon="x"></b-icon></b-badge>
          </b-link>
        </template>
      </b-table>
      <div class="text-right mt-3">
        <b-button variant="success" class="mr-1" size="sm" :disabled="!isAvgOrdersAvailable" @click="onAvgOrdersButtonClick">Усреднить</b-button>
        <b-button variant="danger" size="sm" :disabled="!isCancelOrdersAvailable" @click="onCancelOrdersButtonClick">Отменить</b-button>
      </div>
    </b-modal>
    <b-modal id="calc-modal" v-model="isCalcModalOpen" @show="onCalcModalShow" @hide="arbForm.showPopover = true" size="xl" title="Калькулятор доходности фьючерса" hide-footer>
      <b-overlay :show="calcForm.isDataLoading" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form>
        <b-row>
          <b-col class="pr-1">
            <b-form-group label="Размер коридора">
              <b-form-input size="sm" v-model="calcForm.depositPerCorridor" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="pr-1 pl-1">
            <b-form-group label="Коэф. профита">
              <b-form-input size="sm" v-model="calcForm.profitRatio" step="0.1" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="pr-1 pl-1">
            <b-form-group label="Объём холдинга">
              <b-form-input size="sm" v-model="calcForm.holdingPart" min="25" max="100" type="number"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="pl-1">
            <b-form-group label="Комиссия за ордер">
              <b-form-input size="sm" v-model="calcForm.fee" step="0.01" min="0.07" max="0.09" type="number"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-row v-if="!calcForm.tooBadSettingsError" class="justify-content-center">
        <b-col class="pr-1">
          <div class="info-block">
            Стоимость ордера: <b>{{ calcForm.orderPrice }} USDT</b>
          </div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div class="info-block">
            Убыток: <b>{{ calcForm.loss }} USDT</b>
          </div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div class="info-block">
            Прибыль: <b>{{ calcForm.profit }} USDT</b>
          </div>
        </b-col>
        <b-col class="pl-1">
          <div class="info-block">
            Прибыль с холдинга: <b>{{ calcForm.holdingProfit }} USDT</b>
          </div>
        </b-col>
      </b-row>
      <b-alert show v-else variant="danger">
        Слишком низкие значения настроек для работы. Увеличьте размер коридора или коэф., профита
      </b-alert>
    </b-modal>
    <b-modal id="settings-modal" @show="arbForm.showPopover = false" v-model="isSettingsModalShown" title="Настройки" hide-footer>
      <b-overlay :show="settingsForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form @submit.prevent="onSettingsFormSubmit">
        <b-row>
          <b-col class="pr-1">
            <b-form-group label="Размер коридора">
              <b-form-input type="number" min="10" step="0.1" v-model="settingsForm.corridorSize" size="sm" placeholder="Размер коридора"></b-form-input>
            </b-form-group>
            <b-form-group label="Депозит">
              <b-form-input type="number" min="10" step="0.1" v-model="settingsForm.deposit" size="sm" placeholder="Депозит"></b-form-input>
            </b-form-group>
            <b-form-group label="Проскальзывание">
              <b-form-input type="number" min="0.1" max="5" step="0.1" v-model="settingsForm.slip" size="sm" placeholder="Проскальзывание"></b-form-input>
            </b-form-group>
            <b-form-group label="Баланс монет">
              <b-form-input type="number" min="0" step="0.000001" v-model="settingsForm.coinsBalance" size="sm" placeholder="Баланс монет"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="pl-1">
            <b-form-group label="Комиссия за ордер">
              <b-form-input type="number" max="0.1" min="0" step="0.01" v-model="settingsForm.fee" size="sm" placeholder="Комиссия"></b-form-input>
            </b-form-group>
            <b-form-group :label-class="profitRatioLabelClass" label="Коэф. профита">
              <b-form-input type="number" max="3" min="0.2" step="0.01" v-model="settingsForm.profitRatio" size="sm" placeholder="Коэф. профита"></b-form-input>
            </b-form-group>
            <b-form-group :label-class="majorProfitRatioLabelClass" label="Доп. коэф. профита">
              <b-form-input type="number" max="3" min="0.2" step="0.01" :disabled="!settingsForm.isProfitRatioMajor" v-model="settingsForm.profitRatioMajor" size="sm" placeholder="Доп. коэф. профита"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mt-0 mb-3"/>
        <div class="mb-1">
          <b-form-checkbox v-model="settingsForm.isProfitRatioMajor" switch>Изменить коэф. профита в случае падения</b-form-checkbox>
        </div>
        <div>
          <b-form-checkbox v-model="settingsForm.stopAfterEc" switch>Остановка после проскальзывания</b-form-checkbox>
        </div>
        <b-row class="mt-2">
          <b-col>
            <b-button type="button" @click="switchProfitRatio" :disabled="!settingsForm.isProfitRatioMajor" variant="dark" size="sm">Переключить коэф. профита</b-button>
          </b-col>
          <b-col class="text-right">
            <b-button type="submit" variant="dark" size="sm">Сохранить</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'TradeAssetPage',
    data() {
      return {
        updatePageDataInterval: null,
        incomes: {
          month: null,
          today: null,
          totally: null,
        },
        arbForm: {
          isArbEnabled: false,
          isIncShortEnabled: false,
          hpPart: 100,
          showPopover: false,
          state: null
        },
        assets: [
        ],
        asset: {
          left: null,
          right: null
        },
        holdingOrders: {
          state: null,
          items: [],
          selectedItems: [],
          fields: [
            {
              label: 'Дата создания',
              key: 'created_at',
              sortable: true,
            },
            {
              label: 'Цена',
              key: 'price',
              sortable: true,
            },
            {
              label: 'Объём',
              key: 'size',
              sortable: true,
            },
            {
              label: 'Затрачено',
              key: 'spent',
              sortable: true,
            },
            {
              label: 'Цена открытия',
              key: 'open',
              sortable: true,
            },
            {
              label: 'Комиссии',
              key: 'fee',
              sortable: true,
            },
            {
              label: '',
              key: 'is_avg'
            },
          ]
        },
        calcForm: {
          tooBadSettingsError: true,
          price: null,
          maxLv: null,
          mmt: null,
          multiplier: null,
          sizeRounding: null,
          priceRounding: null,
          holdingPart: null,
          holdingProfit: null,
          profitRatio: null,
          depositPerCorridor: null,
          fee: null,
          orderPrice: null,
          profit: null,
          loss: null,
          isDataLoading: true
        },
        settingsForm: {
          coinsBalance: null,
          isProfitRatioMajor: false,
          isMajorRatioUsed: false,
          corridorSize: null,
          deposit: null,
          profitRatio: null,
          profitRatioMajor: null,
          fee: null,
          state: null,
          slip: null,
          stopAfterEc: null
        },
        setting: {
          usedDeposit: null,
          freeDeposit: null,
          timeLeft: null
        },
        log: null,
        isAvgOrdersAvailable: false,
        isCancelOrdersAvailable: false,
        isCalcModalOpen: false,
        pageState: null,
        isArbAvailable: false,
        isBotEnabled: false,
        isUrgnetlyAction: false,
        isStatusUpdated: false,
        isSettingsModalShown: false,
        currentAsset: null,
        /*keyTypes: [
        ]*/
      }
    },
    mounted() {
      this.currentAsset = this.$route.params.id
      this.updatePageDataInterval = setInterval(this.getAssetData, 15000)
      this.getAssetData()
      this.getUserAssets()
    },
    beforeDestroy () {
      clearInterval(this.updatePageDataInterval)
    },
    computed: {
      profitRatioLabelClass() {
        return !this.settingsForm.isProfitRatioMajor || !this.settingsForm.isMajorRatioUsed ? 'text-bold' : ''
      },
      majorProfitRatioLabelClass() {
        return this.settingsForm.isProfitRatioMajor && this.settingsForm.isMajorRatioUsed ? 'text-bold' : ''
      }
    },
    watch: {
      currentAsset: function(newValue, oldValue) {
        if(oldValue != null) {
          clearInterval(this.updatePageDataInterval)
          this.$router.push({ name: 'TradeAssetPage', params: { id: newValue } }).catch(()=>{})
        }
      },
      'calcForm.depositPerCorridor': function() {
        this.calcEstProfitLoss()
      },
      'calcForm.profitRatio': function() {
        this.calcEstProfitLoss()
      },
      'calcForm.holdingPart': function() {
        this.calcEstProfitLoss()
      },
      'calcForm.fee': function() {
        this.calcEstProfitLoss()
      }
    },
    methods: {
      getUserAssets() {
        apiRequest.get('get_user_assets')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.assets = response.data['data']
          }
        })
      },
      switchProfitRatio() {
        this.settingsForm.isMajorRatioUsed = !this.settingsForm.isMajorRatioUsed
      },
      updateAvgState() {
        let selected = this.holdingOrders.selectedItems.map(n => n['id'])
        if(selected.length >= 1) {
          this.isCancelOrdersAvailable = true
          if(selected.length >= 2) {
            this.isAvgOrdersAvailable = true
          } else {
            this.isAvgOrdersAvailable = false
          }
        } else {
          this.isCancelOrdersAvailable = false
          this.isAvgOrdersAvailable = false
        }
      },
      updateArbState(isCalledByArb) {
        if(!isCalledByArb && !this.arbForm.isArbEnabled) {
          return
        }
        if(!this.arbForm.isArbEnabled) {
          this.arbForm.isIncShortEnabled = false
        }
        this.arbForm.state = this.dataStates.LOADING
        let args = {
          'setting_id': this.currentAsset,
        }
        if(this.arbForm.isArbEnabled) {
          if(!this.arbForm.isIncShortEnabled) {
            args['state'] = 1
          } else {
            args['state'] = 2
          }
        } else {
          args['state'] = 0
        }
        let data = this.serializeParams(args)
        apiRequest.post('set_arb_state', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            if(this.isBotEnabled) {
              this.showAlert(true, response.data['message'])
            }
            this.arbForm.isUpdated = false
            setTimeout(this.getAssetData, 3500)
          } else {
            this.showAlert(false, response.data['message'])
            if(isCalledByArb) {
              this.arbForm.isArbEnabled = !this.arbForm.isArbEnabled
            } else {
              this.arbForm.isIncShortEnabled = !this.arbForm.isIncShortEnabled
            }
          }
        })
        .finally(() => {
          this.arbForm.state = this.dataStates.LOADED
        })
      },
      fixTableLabels() {
        for(let i=0;i<this.holdingOrders.fields.length;i++) {
          if(this.holdingOrders.fields[i]['key'] === 'spent') {
            this.holdingOrders.fields[i]['label'] = `Потрачено (${this.asset.right})`
          } else if(this.holdingOrders.fields[i]['key'] === 'balance') {
            this.holdingOrders.fields[i]['label'] = `Остаток (${this.asset.right})`
          }
        }
      },
      getAssetData() {
        this.pageState = this.dataStates.LOADING
        apiRequest.post('get_trading_setting', `id=${this.currentAsset}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.pageState = this.dataStates.LOADED
            this.incomes.month = response.data['data']['incomes']['month']
            this.incomes.today = response.data['data']['incomes']['today']
            this.incomes.totally = response.data['data']['incomes']['totally']
            this.setting.usedDeposit = response.data['data']['asset']['used_deposit']
            this.setting.freeDeposit = response.data['data']['asset']['free_deposit']
            this.setting.timeLeft = response.data['data']['asset']['time_left']
            if(!this.isSettingsModalShown) {
              this.settingsForm.corridorSize = response.data['data']['asset']['per_corridor']
              this.settingsForm.deposit = response.data['data']['asset']['free_deposit']
              this.settingsForm.coinsBalance = response.data['data']['asset']['coins_balance']
              this.settingsForm.profitRatio = response.data['data']['asset']['profit_ratio']
              this.settingsForm.profitRatioMajor = response.data['data']['asset']['profit_ratio_major']
              this.settingsForm.isProfitRatioMajor = this.settingsForm.profitRatioMajor !== null
              this.settingsForm.isMajorRatioUsed = response.data['data']['asset']['is_major_ratio_used']
              this.settingsForm.isMajorRatioUsed = this.settingsForm.isMajorRatioUsed == 1
              this.settingsForm.fee = response.data['data']['asset']['order_fee']
              this.settingsForm.slip = response.data['data']['asset']['slip']
              this.settingsForm.stopAfterEc = response.data['data']['asset']['stop_after_ec'] == 1
            }
            this.isStatusUpdated = response.data['data']['asset']['is_status_updated'] == 1
            this.isBotEnabled = response.data['data']['asset']['status'] == 'enabled'
            this.isUrgnetlyAction = response.data['data']['asset']['status'].startsWith('urgently')
            this.asset.left = response.data['data']['symbol']['asset_left']
            this.asset.right = response.data['data']['symbol']['asset_right']
            this.isArbAvailable = response.data['data']['symbol']['is_arb_available'] == 1
            this.arbForm.isUpdated = response.data['data']['asset']['is_arb_state_updated'] == 1

            if(!this.arbForm.showPopover) {
              this.arbForm.hpPart = response.data['data']['asset']['hp_part']
            }

            if(!this.isCalcModalOpen) {
              this.calcForm.holdingPart = this.arbForm.hpPart
              this.calcForm.profitRatio = this.settingsForm.profitRatio
              this.calcForm.depositPerCorridor = this.settingsForm.corridorSize
              this.calcForm.fee = this.settingsForm.fee
            }

            if(response.data['data']['asset']['arb_state'] == 0) {
              this.arbForm.isArbEnabled = false
              this.arbForm.isIncShortEnabled = false
            } else if(response.data['data']['asset']['arb_state'] == 1) {
              this.arbForm.isArbEnabled = true
              this.arbForm.isIncShortEnabled = false
            } else if(response.data['data']['asset']['arb_state'] == 2) {
              this.arbForm.isArbEnabled = true
              this.arbForm.isIncShortEnabled = true
            }
            this.log = response.data['data']['log']
            // this.keyTypes = response.data['data']['users_keys']
            this.fixTableLabels()
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.$router.push({ name: 'TradePage' }).catch(()=>{})
          this.pageState = this.dataStates.ERROR
        })
      },
      onArbButtonClick()  {
        this.arbForm.showPopover = !this.arbForm.showPopover
      },
      onBackButtonClick() {
        this.$router.push({ name: 'TradePage' }).catch(()=>{})
      },
      onHoldingOrdersOpen() {
        let args = {
          'setting_id': this.currentAsset,
        }
        this.arbForm.showPopover = false
        this.holdingOrders.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('get_holding_orders', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.holdingOrders.items = response.data['data']
            for(let i=0;i<this.holdingOrders.items.length;i++) {
              this.holdingOrders.items[i]['isSelected'] = false
            }
            this.$bvModal.hide('settings-modal')
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.updateAvgState()
          this.holdingOrders.state = this.dataStates.LOADED
          this.$forceUpdate()
        })
      },
      calcLiqPrice(openPrice, lv) {
        let result = this.floor(openPrice + openPrice / lv - openPrice * this.calcForm.mmt, this.calcForm.priceRounding)
        return result
      },
      calcLiqSl(openPrice, stopLossPrice, takeProfitPrice, pureOrderSize) {

        let maxLv = this.calcForm.maxLv
        let minLp = this.floor(openPrice + (stopLossPrice - openPrice) * 2, this.calcForm.priceRounding)
        let minLv = null

        for(let lv = maxLv;lv>=0;lv--) {
          let lp = this.calcLiqPrice(openPrice, lv)
          if(minLp <= lp) {
            minLv = lv
            break
          }
        }

        let orderPrice = this.floor(openPrice * pureOrderSize, 4)

        let lotsAmount = null
        let realOrderSize = null

        for(let lv = minLv;lv<=maxLv+1;lv++) {
          let orderSize = pureOrderSize * lv
          lotsAmount = this.round(orderSize / this.calcForm.multiplier, 0);
          realOrderSize = this.round(this.calcForm.multiplier * lotsAmount, this.calcForm.sizeRounding);
          if(realOrderSize <= orderSize) {
            break
          }
        }
        
        if(realOrderSize < this.calcForm.multiplier) {
          return null
        }

        let tLoss = this.calcEstLoss(openPrice, stopLossPrice, realOrderSize)
        let tProfit = this.calcEstProfit(openPrice, takeProfitPrice, realOrderSize)

        return {
          'loss': tLoss,
          'profit': tProfit,
          'orderPrice': orderPrice
        }
      },
      onCancelAvgOrdersButtonClick(order_id) {
        this.$bvModal.msgBoxConfirm('Отменить усреднение ордера?', {
          title: 'Подтверждение',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Да',
          cancelVariant: 'danger',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            let args = {
              'setting_id': this.currentAsset,
              'order_ids': order_id
            }
            this.holdingOrders.state = this.dataStates.LOADING
            let data = this.serializeParams(args)
            apiRequest.post('cancel_avg_orders', data)
            .then(() => {
              this.onHoldingOrdersOpen()
            })
          }
        })
      },
      onOrdersRowsSelected(items) {
        this.holdingOrders.selectedItems = items
        this.updateAvgState()
      },
      onAvgOrdersButtonClick() {
        let selected = this.holdingOrders.selectedItems.map(n => n['id'])
        let args = {
          'setting_id': this.currentAsset,
          'order_ids': selected.join(',')
        }
        this.holdingOrders.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('avg_orders', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            let price = response.data['data']['price']
            let size = response.data['data']['size']
            let msg = `Будет размещён ордер по усреднённой цене ${price} объёмом ${size}, а выбранные будут отменены. Вы уверены?`
            this.$bvModal.msgBoxConfirm(msg, {
              title: 'Подтверждение',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Да',
              cancelTitle: 'Нет',
              footerClass: 'p-2',
              hideHeaderClose: false
            })
            .then(value => {
              if(value) {
                let args = {
                  'setting_id': this.currentAsset,
                  'order_ids': selected.join(','),
                  'place_orders': '1'
                }
                let data = this.serializeParams(args)
                this.holdingOrders.state = this.dataStates.LOADING
                apiRequest.post('avg_orders', data)
                .then(response => {
                  if(response.data['status'] != 'success') {
                    this.showAlert(false, response.data['message'])
                  }
                })
                .finally(() => {
                  this.onHoldingOrdersOpen()
                })
              }
            })
          } else {
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.holdingOrders.state = this.dataStates.LOADED
        })
      },
      onCancelOrdersButtonClick() {
        this.$bvModal.msgBoxConfirm('Вы уверены что хотите отменить выбранные ордера?', {
          title: 'Подтверждение',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelVariant: 'success',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            let selected = this.holdingOrders.selectedItems.map(n => n['id'])
            let args = {
              'setting_id': this.currentAsset,
              'order_ids': selected.join(',')
            }
            this.holdingOrders.state = this.dataStates.LOADING
            let data = this.serializeParams(args)
            apiRequest.post('cancel_orders', data)
            .then(response => {
              if(response.data['status'] != 'success') {
                this.showAlert(false, response.data['message'])
              }
            })
            .finally(() => {
              this.onHoldingOrdersOpen()
            })
          }
        })
      },
      calcEstLoss(openPrice, stopPrice, vol) {
        return this.floor((stopPrice - openPrice) * vol, 4)
      },
      calcEstProfit(openPrice, takeProfitPrice, vol) {
        return this.floor((openPrice - takeProfitPrice) * vol, 4)
      },
      calcEstProfitLoss() {
        if(!this.calcForm.holdingPart || !this.calcForm.profitRatio || 
           !this.calcForm.depositPerCorridor || !this.calcForm.fee || this.calcForm.isDataLoading) {
          return
        }

        let openPrice = this.floor(this.calcForm.price, this.calcForm.priceRounding)
        let prCalc = openPrice * this.calcForm.profitRatio / 100
        let feeCalc = openPrice * this.calcForm.fee / 100
        let stopLossPrice = this.floor(openPrice + prCalc * 2 + feeCalc * 8, this.calcForm.priceRounding)
        let takeProfitPrice = this.floor(openPrice - prCalc * 3, this.calcForm.priceRounding)
        let holdingProfit = this.calcForm.depositPerCorridor * this.calcForm.profitRatio / 100 / 2 * 1.7
        let orderSize = holdingProfit / openPrice * this.calcForm.holdingPart / 100
        let lqData = this.calcLiqSl(openPrice, stopLossPrice, takeProfitPrice, orderSize)
        if(lqData != null) {
          this.calcForm.holdingProfit = holdingProfit.toFixed(4)
          this.calcForm.orderPrice = lqData['orderPrice'].toFixed(4)
          this.calcForm.profit = lqData['profit'].toFixed(4)
          this.calcForm.loss = lqData['loss'].toFixed(4)
          this.calcForm.tooBadSettingsError = false          
        } else {
          this.calcForm.tooBadSettingsError = true
        }
      },
      onCalcModalShow() {
        this.arbForm.showPopover = false
        this.calcForm.isDataLoading = true
        apiRequest.post('get_fut_asset_info', `setting_id=${this.currentAsset}`)
        .then(response => {
          this.calcForm.maxLv = response.data['data']['max_leverage']
          this.calcForm.mmt = response.data['data']['mt_margin']
          this.calcForm.multiplier = response.data['data']['multiplier']
          this.calcForm.sizeRounding = response.data['data']['size_round_str']
          this.calcForm.priceRounding = response.data['data']['price_round_str']
          this.calcForm.price = response.data['data']['price']
          this.calcForm.holdingPart = this.arbForm.hpPart
        })
        .finally(() => {
          this.calcForm.isDataLoading = false
          this.calcEstProfitLoss()
        })
      },
      onSettingsFormSubmit() {
        let profitRatioMajor = this.settingsForm.isProfitRatioMajor ? this.settingsForm.profitRatioMajor : ''
        let isMajorRatioUsed = this.settingsForm.isProfitRatioMajor ? this.settingsForm.isMajorRatioUsed : '0'
        let args = {
          'per_corridor': this.settingsForm.corridorSize,
          'deposit': this.settingsForm.deposit,
          'coins_balance': this.settingsForm.coinsBalance,
          'profit_ratio': this.settingsForm.profitRatio,
          'profit_ratio_major': profitRatioMajor,
          'is_major_ratio_used': isMajorRatioUsed ? '1' : '0',
          'fee_per_order': this.settingsForm.fee,
          'slip': this.settingsForm.slip,
          'asset_id': this.currentAsset,
          'stop_after_ec': this.settingsForm.stopAfterEc ? '1' : '0'
        }
        this.settingsForm.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('set_trading_setting', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.$bvModal.hide('settings-modal')
            this.getAssetData()
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.settingsForm.state = this.dataStates.LOADED
        })
      },
      onStartButtonClick() {
        this.setAssetStatus('enabled')
      },
      onStopButtonClick() {
        this.setAssetStatus('disabled')
      },
      onRestartButtonClick() {
        this.setAssetStatus('restarted')
      },
      onExitButtonClick() {
        let msg = [
          this.$createElement('div', '1. Остановить работу и выключить бота.'),
          this.$createElement('div', '2. Переместить в холдинг и прервать работу бота.'),
        ]
        this.$bvModal.msgBoxConfirm(msg, {
          title: 'Прервать',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'Вариант 2',
          cancelVariant: 'secondary',
          cancelTitle: 'Вариант 1',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            this.setAssetStatus('urgently_disabled', false, true)
          } else if(value === false) {
            this.setAssetStatus('urgently_disabled', false, false)
          }
        })
      },
      onCleanButtonClick() {
        this.$bvModal.msgBoxConfirm('Очистить журнал бота?', {
          title: 'Подтверждение',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value) {
            let args = {
              'id': this.currentAsset
            }
            let data = this.serializeParams(args)
            apiRequest.post('clean_asset_log', data)
            .then(response => {
              let success = false
              if(response.data['status'] == 'success') {
                success = true
                this.log = null
              }
              this.showAlert(success, response.data['message'])
            })
          }
        })
      },
      onSaveHpButtonClick() {
        let args = {
          'setting_id': this.currentAsset,
          'hp_part': this.arbForm.hpPart
        }
        this.arbForm.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('save_hp', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.showAlert(true, response.data['message'])
          }
        })
        .finally(() => {
          this.arbForm.state = this.dataStates.LOADED
        })
      },
      onRefreshButtonClick() {
        this.getAssetData()
      },
      onCancelActionClick() {
        if(this.isBotEnabled) {
          this.setAssetStatus('disabled', true, false, true)
        } else {
          this.setAssetStatus('enabled', true, false, true)
        }
      },
      setAssetStatus(status, setUpdatedState=false, setCancelOrders=false, back=false) {
        let args = {
          'id': this.currentAsset,
          'status': status,
          'back': back ? 1:0
        }
        if(setUpdatedState) {
          args['updated'] = 1
        }
        if(setCancelOrders) {
          args['cancel_orders'] = 1
        }
        let data = this.serializeParams(args)
        apiRequest.post('set_trading_setting_status', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.isBotEnabled = status == 'enabled'
            this.isUrgnetlyAction = status.startsWith('urgently')
            this.isStatusUpdated = setUpdatedState
            setTimeout(this.getAssetData, 3500)
          }
          this.showAlert(success, response.data['message'])
        })
      }
    }
  }
</script>
