<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-overlay :show="orders.state == dataStates.LOADING">
          <b-card>
            <b-row class="justify-content-center">
              <b-col class="mb-3" lg="6" xl="2">
                <b-form-select size="sm" v-model="filterForm.keyType" :options="keyTypes">
                </b-form-select>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-form-select size="sm" v-model="filterForm.asset" :options="visibleAssets">
                </b-form-select>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-form-select size="sm" v-model="filterForm.settingType" :options="settingTypes">
                </b-form-select>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-row>
                  <b-col :class="filterForm.createdAt.from != null ? 'pr-0' : ''">
                    <b-form-datepicker placeholder="Начало периода" locale="ru" :date-format-options="defaultDateTimeFormat" :style="filterForm.createdAt.from != null ? 'border-top-right-radius: 0px;border-bottom-right-radius: 0px;' : ''" size="sm" v-model="filterForm.createdAt.from">
                    </b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="pl-0" v-if="filterForm.createdAt.from != null">
                    <b-button block variant="dark" @click="filterForm.createdAt.from=null" size="sm" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;">x</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-row>
                  <b-col :class="filterForm.createdAt.to != null ? 'pr-0' : ''">
                    <b-form-datepicker placeholder="Конец периода" locale="ru" :date-format-options="defaultDateTimeFormat" :style="filterForm.createdAt.to != null ? 'border-top-right-radius: 0px;border-bottom-right-radius: 0px;' : ''" size="sm" v-model="filterForm.createdAt.to">
                    </b-form-datepicker>
                  </b-col>
                  <b-col cols="2" class="pl-0" v-if="filterForm.createdAt.to != null">
                    <b-button block variant="dark" @click="filterForm.createdAt.to=null" size="sm" style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;">x</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col class="mb-3" lg="6" xl="2">
                <b-button @click="onApplyButtonClick" variant="dark" block size="sm">Применить</b-button>
              </b-col>
            </b-row>
            <b-table head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="orders.items" :fields="orders.fields" show-empty :emptyText="emptyTableText">
              <template #empty="scope">
                <center>
                  {{ scope.emptyText }}
                </center>
              </template>
              <template #cell(fee)="data">
                {{ parseFloat(data.value || '0').toFixed(6) }} USDT
              </template>
              <template #cell(spent)="data">
                {{ parseFloat(data.value || '0').toFixed(2) }} USDT
              </template>
            </b-table>
            <b-pagination @input="onPageChanged" class="mb-0 mt-2" align="right"
              size="sm" v-model="orders.currentPage" :total-rows="orders.totalCount" :per-page="orders.itemsPerPage">
            </b-pagination>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'HoldingOrders',
    data() {
      return {
        settingTypes: [
          { text: 'Spot & Futures', value: 'sf' },
          { text: 'Infinity Trade', value: 'it' }
        ],
        currentFilterSettings: {
          settingType: 'sf',
          keyType: null,
          asset: null,
          createdAt: {
            from: null,
            to: null
          }
        },
        filterForm: {
          settingType: 'sf',
          keyType: null,
          asset: null,
          createdAt: {
            from: null,
            to: null
          }
        },
        assets: [],
        visibleAssets: [],
        keyTypes: [],
        orders: {
          state: null,
          items: [],
          fields: [
            {
              label: 'Биржа',
              key: 'exchange',
              sortable: true
            },
            {
              label: 'Валютная пара',
              key: 'asset',
              sortable: true
            },
            {
              label: 'Цена',
              key: 'price',
              sortable: true
            },
            {
              label: 'Объём',
              key: 'size',
              sortable: true
            },
            {
              label: 'Потрачено',
              key: 'spent',
              sortable: true
            },
            {
              label: 'Комиссии',
              key: 'fee',
              sortable: true
            },
            {
              label: 'Дата создания',
              key: 'created_at',
              sortable: true
            },
          ],
          currentPage: 1,
          totalCount: null,
          itemsPerPage: null,
        }
      }
    },
    mounted() {
      this.getOrders()
    },
    watch: {
      'filterForm.keyType': function(value) {
        this.modAssetsOnKeyTypeChange(value)
      },
      'visibleAssets': function(value) {
        if(value.length == 0 || value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Все валюты',
            'disabled': false
          }
          value.unshift(itm)
        }
      },
      'keyTypes': function(value) {
        if(value.length == 0) {
          let itm = {
            'value': null,
            'text': 'Биржи недоступны',
            'disabled': true
          }
          value.unshift(itm)
        } else if(value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Все биржи',
            'disabled': false
          }
          value.unshift(itm)
        }
      }
    },
    methods: {
      onRefreshButtonClick() {
        this.getOrders()
      },
      onPageChanged(value) {
        this.orders.currentPage = value
        this.getOrders()
      },
      modAssetsOnKeyTypeChange(value) {
        let results = []
        for (let asset of this.assets) {
          if(value == null || asset['key_type_id'] == value) {
            let title = this.symbols[asset['symbol_id']]
            results.push({
              'value': asset['id'],
              'text': title
            })
          }
        }
        this.visibleAssets = results
      },
      onApplyButtonClick() {
        Object.assign(this.currentFilterSettings, this.filterForm)
        /*this.currentFilterSettings.keyType = this.filterForm.keyType
        this.currentFilterSettings.settingType = this.filterForm.settingType
        this.currentFilterSettings.asset = this.filterForm.asset
        this.currentFilterSettings.createdAt.from = this.filterForm.createdAt.from
        this.currentFilterSettings.createdAt.to = this.filterForm.createdAt.to*/
        this.getOrders()
      },
      getOrders() {
        let args = {
          'key_type': this.currentFilterSettings.keyType,
          'asset': this.currentFilterSettings.asset,
          'from': this.currentFilterSettings.createdAt.from,
          'to': this.currentFilterSettings.createdAt.to,
          'type': this.currentFilterSettings.settingType,
          'page': this.orders.currentPage
        }
        let data = this.serializeParams(args)
        this.orders.state = this.dataStates.LOADING
        this.visibleAssets = []
        apiRequest.post(`get_all_holding_orders`, data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.orders.totalCount = response.data['data']['count']
            this.orders.itemsPerPage = response.data['data']['per_page']
            this.orders.items = response.data['data']['orders']
            this.assets = response.data['data']['assets']
            this.symbols = response.data['data']['symbols']
            this.keyTypes = response.data['data']['key_types']
            this.orders.state = this.dataStates.LOADED
            this.modAssetsOnKeyTypeChange(this.filterForm.keyType)
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
      }
    }
  }
</script>
