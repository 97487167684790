import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import { 
  BAlert,
  BSidebar,
  BLink,
  BButton,
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNav,
  BNavItem,
  BFormTextarea,
  BForm,
  BPopover,
  BFormInput,
  BOverlay,
  BFormText,
  BFormSelect,
  BFormGroup,
  BFormDatepicker,
  BButtonGroup,
  BPagination,
  BImg,
  BIconArrowClockwise,
  LayoutPlugin, 
  ModalPlugin, 
  CardPlugin, 
  DropdownPlugin,
  BadgePlugin,
  TablePlugin,
  InputGroupPlugin,
  ToastPlugin,
  FormCheckboxPlugin,
  FormRadioPlugin,
  TooltipPlugin,
  FormSpinbuttonPlugin,
  BIcon, 
  BIconPersonFill, 
  BIconBarChartLineFill, 
  BIconKeyFill, 
  BIconCalendarWeekFill, 
  BIconDiagram2Fill,
  BIconPeopleFill,
  BIconPlayCircleFill,
  BIconStopCircleFill,
  BIconChatSquareTextFill,
  BIconPlusCircle,
  BIconXCircle,
  BIconChatLeftText,
  BIconListUl,
  BIconTextareaT,
  BIconTrash,
  BIconCalculator,
  BIconPower,
  BIconPlus,
  BIconX,
  BIconClock,
  TabsPlugin
} from 'bootstrap-vue'

import App from './App.vue'
import DashboardPage from './components/DashboardPage.vue'
import TradeAssetPage from './components/TradeAssetPage.vue'
import TradeAssetItPage from './components/TradeAssetItPage.vue'
import TradePage from './components/TradePage.vue'
import ApiKeysPage from './components/ApiKeysPage.vue'
import LoginPage from './components/LoginPage.vue'
import SignupPage from './components/SignupPage.vue'
import ReferralsPage from './components/ReferralsPage.vue'
import StatsPage from './components/StatsPage.vue'
import TermsPage from './components/TermsPage.vue'
import HoldingOrdersPage from './components/HoldingOrdersPage.vue'
import NotFoundPage from './components/NotFoundPage.vue'
import BadAccountPage from './components/BadAccountPage.vue'
import SupportPage from './components/SupportPage.vue'
import RestorePasswordPage from './components/RestorePasswordPage.vue'
import Template from './components/Template.vue'

import './scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
Vue.use(VueRouter)
Vue.use(ToastPlugin)
Vue.use(BadgePlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormRadioPlugin)
Vue.use(TooltipPlugin)
Vue.use(TabsPlugin)
Vue.use(FormSpinbuttonPlugin)
Vue.use(InputGroupPlugin)

Vue.component('b-sidebar', BSidebar)
Vue.component('b-button', BButton)
Vue.component('b-navbar', BNavbar)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-nav', BNav)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-link', BLink)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form', BForm)
Vue.component('b-form-text', BFormText)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-overlay', BOverlay)
Vue.component('b-button-group', BButtonGroup)
Vue.component('b-alert', BAlert)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-pagination', BPagination)
Vue.component('b-img', BImg)
Vue.component('b-popover', BPopover)

// Icons
Vue.component('BIcon', BIcon)
Vue.component('BIconCalculator', BIconCalculator)
Vue.component('BIconClock', BIconClock)
Vue.component('BIconPersonFill', BIconPersonFill)
Vue.component('BIconBarChartLineFill', BIconBarChartLineFill)
Vue.component('BIconKeyFill', BIconKeyFill)
Vue.component('BIconCalendarWeekFill', BIconCalendarWeekFill)
Vue.component('BIconDiagram2Fill', BIconDiagram2Fill)
Vue.component('BIconPeopleFill', BIconPeopleFill)
Vue.component('BIconPlusCircle', BIconPlusCircle)
Vue.component('BIconChatLeftText', BIconChatLeftText)
Vue.component('BIconChatSquareTextFill', BIconChatSquareTextFill)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconPower', BIconPower)
Vue.component('BIconListUl', BIconListUl)
Vue.component('BIconArrowClockwise', BIconArrowClockwise)
Vue.component('BIconTextareaT', BIconTextareaT)
Vue.component('BIconX', BIconX)
Vue.component('BIconPlayCircleFill', BIconPlayCircleFill)
Vue.component('BIconStopCircleFill', BIconStopCircleFill)
Vue.component('BIconXCircle', BIconXCircle)
Vue.component('BIconPlus', BIconPlus)

export const admUrl = process.env.VUE_APP_ADM_URL
export const apiUrl = process.env.VUE_APP_API_URL

export const apiRequest = axios.create({
  withCredentials: true,
  baseURL: apiUrl
})

const DEFAULT_TITLE = 'FBM-BOT'
const routes = [
  {
    path: '/',
    component: Template,
    meta: { title: '' },
    redirect: { name: 'LoginPage' },
    children: [
      {
        name: 'ApiKeysPage',
        path: 'keys',
        component: ApiKeysPage,
        meta: { title: 'Ключи' }
      },
      {
        name: 'TradeAssetPage',
        path: 'trade/:id',
        component: TradeAssetPage,
        meta: { title: 'Торговля' }
      },
      {
        name: 'TradeAssetItPage',
        path: 'trade/inf/:id',
        component: TradeAssetItPage,
        meta: { title: 'Торговля' }
      },
      {
        name: 'TradePage',
        path: 'trade',
        component: TradePage,
        meta: { title: 'Торговля' }
      },
      {
        name: 'DashboardPage',
        path: 'dashboard',
        component: DashboardPage,
        meta: { title: 'Кабинет' }
      },
      {
        name: 'ReferralsPage',
        path: 'referrals',
        component: ReferralsPage,
        meta: { title: 'Мои рефералы' }
      },
      {
        name: 'StatsPage',
        path: 'stats',
        component: StatsPage,
        meta: { title: 'Статистика' }
      },
      {
        name: 'TermsPage',
        path: 'terms',
        component: TermsPage,
        meta: { title: 'Памятка пользователя' }
      },
      {
        name: 'HoldingOrdersPage',
        path: 'horders',
        component: HoldingOrdersPage,
        meta: { title: 'Ордера в холдинге' }
      },
      {
        name: 'SupportPage',
        path: 'support',
        component: SupportPage,
        meta: { title: 'Техническая поддержка' }
      }
    ]
  },
  {
    name: 'SignupPage',
    path: '/signup',
    component: SignupPage,
    meta: { title: 'Регистрация' }
  },
  {
    name: 'LoginPage',
    path: '/login',
    component: LoginPage,
    meta: { title: 'Авторизация' }
  },
  {
    name: 'RestorePasswordPage',
    path: '/restore',
    component: RestorePasswordPage,
    meta: { title: 'Восстановление пароля' }
  },
  {
    name: 'BadAccountPage',
    path: '/profile',
    component: BadAccountPage,
    meta: { title: 'Профиль' }
  },
  {
    name: 'NotFoundPage',
    path: '*',
    component: NotFoundPage,
    meta: { title: 'Не найдено' }
  },
]

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  mode: 'history',
})

Vue.mixin({
  data() {
    return {
      defaultDateTimeFormat: {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      },
      emptyTableText: 'Пусто',
      dataStates: {
        EMPTY: 0,
        LOADING: 1,
        LOADED: 2,
        ERROR: 3
      }
    }
  },
  methods: {
    floor(number, n) {
      var k = Math.pow(10, n);
      return (Math.floor(number * k) / k);
    },
    round(number, n) {
      var k = Math.pow(10, n);
      return (Math.round(number * k) / k);
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    },
    isResponseHasRedirect(response) {
      if('data' in response && response.data != null) {
        if('login_redirect' in response.data) {
          if(response.data['login_redirect'] == true) {
            // Если установлен редирект
            router.push({ name: 'LoginPage' })
            return true
          }
        } else if('status' in response.data) {
          if(response.data['status'] == 'not_activated' || response.data['status'] == 'banned') {
            router.push({ name: 'BadAccountPage' })
          }
          return true
        }
      }
      return false
    },
    serializeParams(obj) {
      var str = [];
      for(var p in obj) {
        if(obj[p] !== null) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }
      return str.join("&");
    },
    showAlert(success, body) {
      let variant = 'danger'
      let title = 'Ошибка'
      if(success) {
        variant = 'success'
        title = 'Успех'
      }
      this.showCustomAlert(title, body, variant)
    },
    showCustomAlert(title, body, variant) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        bodyClass: 'text-break ws-pl',
        toaster: 'b-toaster-bottom-right',
        solid: true
      })
    }
  }
})

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title =  `${DEFAULT_TITLE}: ${to.meta.title}`;
    });
});

Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
