<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-card>
          <span v-html="terms"></span>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'StatsPage',
    data() {
      return {
        terms: null
      }
    },
    mounted() {
      this.getTerms()
    },
    methods: {
      getTerms() {
        apiRequest.get('/get_terms')
        .then(response => {
          if(response.data['status'] == 'error') {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          } else {
            this.terms = response.data['data']
          }
        })
      },
      onRefreshButtonClick() {
        this.getTerms()
      }
    }
  }
</script>

<style>
  ol {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align:justify;
  }
  ol > li {
    padding-bottom: 0.5rem;
  }
</style>
