<template>
  <b-container class="fade-active fade-enter">
    <b-row class="mt-5 justify-content-center">
      <b-col sm="4" class="mt-5">
        <b-overlay :show="form.state == dataStates.LOADING || captcha.state == dataStates.LOADING">
          <b-card no-body>
            <b-card-body class="pt-3">
              <h3 class="mb-3"><b-link style="color: #000;" @click="getBack">«</b-link> Сброс пароля</h3>
              <b-form v-if="!isMessageSent" @submit.prevent="onRestoreFormSubmit">
                <b-form-group>
                  <b-form-input required placeholder="Имя пользователя" :state="Boolean(form.login)" v-model="form.login" size="sm"></b-form-input>
                </b-form-group>
                <b-row class="mb-3">
                  <b-col class="pr-1">
                    <b-form-input required placeholder="Капча" :state="Boolean(form.captcha)" v-model="form.captcha" size="sm"></b-form-input>
                  </b-col>
                  <b-col class="pl-1">
                    <b-img :src="captcha.pic" style="width: 100%; height: 32px;border-radius: 3.2px;" fluid alt="Captcha"></b-img>
                  </b-col>
                </b-row>
                <b-button type="submit" size="sm" block variant="dark">Далее</b-button>
              </b-form>
              <b-form v-else @submit.prevent="onNewPasswordFormSubmit">
                <b-form-group>
                  <b-form-input required placeholder="Код из письма" :state="Boolean(passwordForm.code)" v-model="passwordForm.code" size="sm"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input required type="password" placeholder="Новый пароль" :state="Boolean(passwordForm.password)" v-model="passwordForm.password" size="sm"></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input required type="password" placeholder="Повторите пароль" :state="Boolean(passwordForm.passwordRepeat)" v-model="passwordForm.passwordRepeat" size="sm"></b-form-input>
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-button type="button" size="sm" @click="onBackButtonClick" block variant="dark">Назад</b-button>
                  </b-col>
                  <b-col>
                    <b-button type="submit" size="sm" block variant="dark">Далее</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'RestorePasswordPage',
    data() {
      return {
        isMessageSent: false,
        form: {
          state: null,
          login: null,
          captcha: null
        },
        passwordForm: {
          code: null,
          password: null,
          passwordRepeat: null
        },
        captcha: {
          state: null,
          pic: null
        }
      }
    },
    mounted() {
      this.getPageData()
    },
    beforeDestroy () {
    },
    watch: {
    },
    methods: {
      getBack() {
        this.$router.push({ name: 'LoginPage' })
      },
      onRestoreFormSubmit() {
        this.form.state = this.dataStates.LOADING
        this.captcha.state = this.dataStates.LOADING
        let args = {
          'username': this.form.login,
          'captcha': this.form.captcha
        }
        let data = this.serializeParams(args)
        apiRequest.post('restore_password', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.isMessageSent = true
          } else {
            if(response.data['data'] != null && 'captcha' in response.data['data']) {
              this.captcha.pic = response.data['data']['captcha']
              this.form.captcha = null
            }
          }
          this.showAlert(success, response.data['message'])
          this.captcha.state = this.dataStates.LOADED
          this.form.state = this.dataStates.LOADED
        })
        .catch(() => {
          this.form.state = this.dataStates.ERROR
          this.captcha.state = this.dataStates.ERROR
        })
      },
      onBackButtonClick() {
        this.isMessageSent = false
        this.passwordForm.code = null
        this.passwordForm.password = null
        this.passwordForm.passwordRepeat = null
      },
      onNewPasswordFormSubmit() {
        this.form.state = this.dataStates.LOADING
        let args = {
          'username': this.form.login,
          'code': this.passwordForm.code,
          'password': this.passwordForm.password,
          'password_rep': this.passwordForm.passwordRepeat
        }
        let data = this.serializeParams(args)
        apiRequest.post('new_password', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            setTimeout(() => {
              this.$router.push({ name: 'LoginPage' })
            }, 3000)
          } else {
            this.form.state = this.dataStates.LOADED
          }
          this.showAlert(success, response.data['message'])
        })
        .catch(() => {
          this.form.state = this.dataStates.ERROR
        })
      },
      getPageData() {
        this.captcha.state = this.dataStates.LOADING
        apiRequest.get('page/restore_password')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.captcha.state = this.dataStates.LOADED
            this.captcha.pic = response.data['data']['captcha']
            if(response.data['data']['is_logged_in']) {
              this.$router.push({ name: 'DashboardPage' })
            } else {
              this.$el.classList.remove('fade-enter')
            }
          } else {
            throw 'Error'
          }
        })
        .catch(() => {
          this.captcha.state = this.dataStates.ERROR
        })
      },
    }
  }
</script>

<style scoped>
  .fade-active {
    transition: opacity .2s;
  }
  .fade-enter {
    opacity: 0;
  }
</style>
