<template>
  <b-container class="fade-active fade-enter">
    <b-row class="mt-5 justify-content-center">
      <b-col v-if="!signupForm.success" sm="4" class="mt-5">
        <b-overlay :show="signupForm.state == dataStates.LOADING || captcha.state == dataStates.LOADING">
          <b-card>
            <b-form @submit.prevent="onSignupFormSubmit">
              <b-form-group>
                <b-form-input required placeholder="Имя пользователя" :state="Boolean(signupForm.login)" v-model="signupForm.login" size="sm"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input required placeholder="Пароль" :state="Boolean(signupForm.password)" v-model="signupForm.password" type="password" size="sm"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input required placeholder="Повторите пароль" :state="Boolean(signupForm.passwordRepeat)" v-model="signupForm.passwordRepeat" type="password" size="sm"></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input required placeholder="Эл. почта" :state="Boolean(signupForm.email)" v-model="signupForm.email" type="email" size="sm"></b-form-input>
              </b-form-group>
              <!--<b-form-group>
                <b-form-input required placeholder="Телеграм" :state="Boolean(signupForm.telegram)" v-model="signupForm.telegram" type="text" size="sm"></b-form-input>
              </b-form-group>!-->
              <b-form-group>
                <b-form-input placeholder="Код реферала" :disabled="signupForm.refDisabled" v-model="signupForm.referrerCode" type="text" size="sm"></b-form-input>
              </b-form-group>
              <b-row class="mb-3">
                <b-col class="pr-1">
                  <b-form-input required placeholder="Капча" :state="Boolean(signupForm.captcha)" v-model="signupForm.captcha" size="sm"></b-form-input>
                </b-col>
                <b-col class="pl-1">
                  <b-img :src="captcha.pic" style="width: 100%; height: 32px;border-radius: 3.2px;" fluid alt="Captcha"></b-img>
                </b-col>
              </b-row>
              <b-button type="submit" size="sm" block variant="dark">Далее</b-button>
            </b-form>
          </b-card>
        </b-overlay>
      </b-col>
      <template v-else>
        <div class="text-center">
          <h3>Регистрация завершена.</h3>
        </div>
      </template>
    </b-row>
  </b-container>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'SignupPage',
    data() {
      return {
        captcha: {
          state: null,
          pic: null
        },
        signupForm: {
          email: null,
          state: null,
          login: null,
          password: null,
          passwordRepeat: null,
          refDisabled: false,
          referrerCode: null,
          // telegram: null,
          success: false
        }
      }
    },
    mounted() {
      if(typeof this.$route.query.ref !== 'undefined') {
        this.signupForm.referrerCode = this.$route.query.ref
        this.signupForm.refDisabled = true
      }
      this.getPageData()
    },
    beforeDestroy () {
    },
    watch: {
    },
    methods: {
      getPageData() {
        this.captcha.state = this.dataStates.LOADING
        apiRequest.get('page/signup')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.captcha.state = this.dataStates.LOADED
            let isLoggedIn = response.data['data']['is_logged_in']
            this.captcha.pic = response.data['data']['captcha']
            if(isLoggedIn) {
              this.$router.push({ name: 'DashboardPage' })
            } else {
              this.$el.classList.remove('fade-enter')
            }
          } else {
            throw 'Error'
          }
        })
        .catch(() => {
          this.captcha.state = this.dataStates.ERROR
        })
      },
      onSignupFormSubmit() {
        this.signupForm.state = this.dataStates.LOADING
        this.captcha.state = this.dataStates.LOADING
        let args = {
          'username': this.signupForm.login,
          'email': this.signupForm.email,
          'password': this.signupForm.password,
          'password_rep': this.signupForm.passwordRepeat,
          //'telegram': this.signupForm.telegram,
          'referrer': this.signupForm.referrerCode,
          'captcha': this.signupForm.captcha
        }
        let data = this.serializeParams(args)
        apiRequest.post('signup', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.signupForm.success = true
            this.$router.push({ name: 'BadAccountPage' })
          } else {
            if(response.data['data'] != null && 'captcha' in response.data['data']) {
              this.captcha.pic = response.data['data']['captcha']
              this.signupForm.captcha = null
            }
            this.showAlert(true, response.data['message'])
            this.signupForm.state = this.dataStates.LOADED
            this.captcha.state = this.dataStates.LOADED
          }
        })
        .catch(() => {
          this.signupForm.state = this.dataStates.ERROR
          this.captcha.state = this.dataStates.ERROR
        })
      }
    }
  }
</script>

<style scoped>
  .fade-active {
    transition: opacity .2s;
  }
  .fade-enter {
    opacity: 0;
  }
</style>
