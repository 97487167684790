<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col lg="12">
        <b-card>
          <b-overlay no-wrap :show="referrals.state == dataStates.LOADING || refInfo.state == dataStates.LOADING">
          </b-overlay>
          Накопленный доход: <b style="user-select: all">{{ refInfo.incomes !== null ? `$${parseFloat(refInfo.incomes).toFixed(2)}` : '-' }}</b> <small><b-link v-b-modal.withdrawals-modal>[Вывод средств]</b-link></small><br/>
          Реферальный код: <b style="user-select: all">{{ refInfo.code || '-' }}</b><br/>
          Реферальная ссылка: <b style="user-select: all">{{ refInfo.url || '-' }}</b>
          <hr class="mt-3 mb-4"/>
          <b-table id="referrals-table" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1 mt-2" small hover :items="referrals.items" :fields="referrals.fields" show-empty :emptyText="emptyTableText">
            <template #cell(activated_at)="data">
              {{ data.value || '-' }}
            </template>
            <template #cell(income)="data">
              ${{ parseFloat(data.value).toFixed(2) }}
            </template>
            <template #cell(depth)="data">
              {{ data.value+1 }}
            </template>
            <template #cell(income_prev)="data">
              ${{ parseFloat(data.value).toFixed(2) }}
            </template>
            <template #cell(pay_date)="data">
              {{ data.value || '-' }}
            </template>
            <template #empty="scope">
              <center>
                {{ scope.emptyText }}
              </center>
            </template>
          </b-table>
          <b-pagination @input="onReferralsPageChanged" size="sm" align="right" v-model="referrals.currentPage" :total-rows="referrals.totalCount" :per-page="referrals.itemsPerPage" aria-controls="referrals-table"></b-pagination>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="withdraw-modal" @show="getPaymentTypes" size="md" @hide="onWithdrawModalHide" hide-footer title="Вывод средств">
      <b-overlay no-wrap :show="paymentTypes.state == dataStates.LOADING">
      </b-overlay>
      <b-alert show variant="success">
        Минимальная сумма вывода: <b>{{ withdrawal.minPayout }} USDT</b><br/>Максимальная сумма вывода: <b>{{ withdrawal.maxPayout }} USDT</b> 
      </b-alert>
      <b-form @submit.prevent="onWithdrawFormSubmit">
        <b-row>
          <b-col sm="12" md="5">
            <b-form-group label="Тип оплаты">
              <b-form-select size="sm" text-field="title" value-field="id" :options="paymentTypes.items" v-model="withdrawalRequest.paymentType">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Сумма вывода">
              <b-form-input size="sm" type="number" min="1" max="5000" step="0.01" v-model="withdrawalRequest.amountUsd" placeholder="Сумма"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Кошелёк">
              <b-form-input size="sm" min=1 max=64 type="text" v-model="withdrawalRequest.publicKey" placeholder="Кошелёк"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button type="submit" size="sm" variant="dark">Далее</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal id="withdrawals-modal" size="xl" @show="getWithdrawals" hide-footer title="Вывод средств">
      <b-overlay no-wrap :show="withdrawal.state == dataStates.LOADING">
      </b-overlay>
      <b-table id="withdraw-table" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1 mt-2" small hover :items="withdrawal.items" :fields="withdrawal.fields" show-empty :emptyText="emptyTableText">
      <template #cell(status)="data">
        {{ getWithdrawalStatusName(data.value) }}
      </template>
      <template #cell(actions)="data">
        <b-link v-if="data.item.status == 'queued'" class="action-label" title="Отменить" @click="onCancelWithdrawButtonClick(data.item.id)"><b-icon icon="x-circle"></b-icon></b-link>
        <template v-else>-</template>
      </template>
      <template #empty="scope">
        <center>
          {{ scope.emptyText }}
        </center>
      </template>
    </b-table>  
    <hr class="mt-2 mb-2"/>
    <b-row>
      <b-col sm="2">
        <b-button block @click="onwithdrawalRequestButtonClick" variant="dark" size="sm">Создать заявку</b-button>
      </b-col>
      <b-col>
        <b-pagination @input="onWithdrawPageChanged" size="sm" align="right" v-model="withdrawal.currentPage" :total-rows="withdrawal.totalCount" :per-page="withdrawal.itemsPerPage" aria-controls="withdraw-table"></b-pagination>
      </b-col>
    </b-row>
    </b-modal>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'ReferralsPage',
    data() {
      return {
        refInfo: {
          state: null,
          code: null,
          incomes: null,
          url: null,
        },
        paymentTypes: {
          items: [],
          state: null
        },
        withdrawalRequest: {
          amountUsd: null,
          paymentType: null,
          publicKey: null
        },
        withdrawal: {
          minPayout: null,
          maxPayout: null,
          state: null,
          items: [],
          currentPage: 1,
          totalCount: null,
          itemsPerPage: null,
          fields: [
            {
              label: 'Дата',
              key: 'created_at'
            },
            {
              label: 'Сумма',
              key: 'amount'
            },
            {
              label: 'Сумма (USDT)',
              key: 'amount_usd'
            },
            {
              label: 'Тип вывода',
              key: 'payment_type'
            },
            {
              label: 'Кошелёк',
              key: 'public_key'
            },
            {
              label: 'Статус',
              key: 'status'
            },
            {
              label: 'Действия',
              key: 'actions'
            },
          ]
        },
        referrals: {
          state: null,
          currentPage: 1,
          totalCount: null,
          itemsPerPage: null,
          items: [],
          fields: [
            {
              label: 'Имя пользователя',
              key: 'username'
            },
            {
              label: 'Дата оплаты',
              key: 'pay_date'
            },
            {
              label: 'Доход за текущий месяц',
              key: 'income'
            },
            {
              label: 'Доход за прошлый месяц',
              key: 'income_prev'
            },
          ]
        }
      }
    },
    mounted() {
      this.getReferrals()
      this.getRefInfo()
    },
    methods: {
      onWithdrawFormSubmit() {
        let args = {
          'amount_usd': this.withdrawalRequest.amountUsd,
          'payment_type': this.withdrawalRequest.paymentType,
          'public_key': this.withdrawalRequest.publicKey
        }
        let data = this.serializeParams(args)
        apiRequest.post('request_withdrawal', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.$bvModal.hide('withdraw-modal')
            this.getWithdrawals()
            this.getRefInfo()
          }
          this.showAlert(success, response.data['message'])
        })
      },
      getWithdrawalStatusName(value) {
        if(value == 'done') {
          return 'Выполнено'
        } else if(value == 'queued') {
          return 'В очереди'
        } else if(value == 'error') {
          return 'Ошибка'
        } else if(value == 'canceled') {
          return 'Отменено'
        } else if(value == 'processing') {
          return 'В обработке'
        }
        return '-'
      },
      getPaymentTypes() {
        this.paymentTypes.state = this.dataStates.LOADING
        apiRequest.get('get_payment_types')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.paymentTypes.items = response.data['data']
            if(this.withdrawalRequest.paymentType == null && this.paymentTypes.items.length > 0) {
              this.withdrawalRequest.paymentType = this.paymentTypes.items[0]['id']
            }
            this.paymentTypes.state = this.dataStates.LOADED
          }
        })
        .catch(() => {
          this.paymentTypes.state = this.dataStates.ERROR
        })
      },
      onWithdrawModalHide() {
        this.$bvModal.show('withdrawals-modal')
      },
      onCancelWithdrawButtonClick(withdrawId) {
        this.$bvModal.msgBoxConfirm('Отменить выбранную заявку на вывод?', {
          title: 'Подтверждение отмены',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          cancelVariant: 'dark',
          footerClass: 'p-2',
          hideHeaderClose: false,
        })
        .then(value => {
          if(value) {
            this.withdrawal.state = this.dataStates.LOADING
            apiRequest.post('cancel_withdrawal', `id=${withdrawId}`)
            .then(response => {
              if(response.data['status'] == 'success') {
                this.getWithdrawals()
                this.getRefInfo()
              }
            })
          }
        })
      },
      onRefreshButtonClick() {
        this.getReferrals()
        this.getRefInfo()
      },
      onwithdrawalRequestButtonClick() {
        this.$bvModal.hide('withdrawals-modal')
        this.$bvModal.show('withdraw-modal')
      },
      getWithdrawals() {
        this.withdrawal.state = this.dataStates.LOADING
        apiRequest.post('get_withdrawals', `page=${this.withdrawal.currentPage}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.withdrawal.items = response.data['data']['withdrawals']
            this.withdrawal.totalCount = response.data['data']['count']
            this.withdrawal.itemsPerPage = response.data['data']['per_page']
            this.withdrawal.state = this.dataStates.LOADED
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.withdrawal.state = this.dataStates.ERROR
        })
      },
      onWithdrawPageChanged(value) {
        this.withdrawal.currentPage = value
        this.getWithdrawals()
      },
      onReferralsPageChanged(value) {
        this.referrals.currentPage = value
        this.getReferrals()
      },
      getRefInfo() {
        this.refInfo.state = this.dataStates.LOADING
        apiRequest.get('get_ref_info')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.refInfo.code = response.data['data']['ref_code']
            this.refInfo.incomes = response.data['data']['ref_incomes']
            this.withdrawal.minPayout = response.data['data']['min_withdraw']
            this.withdrawal.maxPayout = response.data['data']['max_withdraw']
            this.refInfo.url = response.data['data']['ref_url']
            if(response.data['data']['ref_type']) {
              let isDepthExists = this.referrals.fields.filter((x) => {return x['key'] == 'depth'}).length > 0
              if(!isDepthExists) {
                this.referrals.fields.unshift({label: 'Линия', key: 'depth' })
              }
            }
            this.refInfo.state = this.dataStates.LOADED
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.refInfo.state = this.dataStates.ERROR
        })
      },
      getReferrals() {
        this.referrals.state = this.dataStates.LOADING
        apiRequest.post('get_referrals', `page=${this.referrals.currentPage}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.referrals.items = response.data['data']['referrals']
            this.referrals.totalCount = response.data['data']['count']
            this.referrals.itemsPerPage = response.data['data']['per_page']
            this.referrals.state = this.dataStates.LOADED
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.referrals.state = this.dataStates.ERROR
        })
      }
    }
  }
</script>
