<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-overlay :show="settingsList.state == dataStates.LOADING">
          <b-card no-body>
            <b-tabs v-model="activeSettingTab" card>
              <b-tab title="Spot & Futures">
                <b-table @row-clicked="onRowClick" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="settingsList.items" :fields="settingsList.fields" show-empty :emptyText="emptyTableText">
                  <template #cell(incomes_today)="data">
                    {{ parseFloat(data.value || 0).toFixed(3) }} USDT
                  </template>
                  <template #cell(free_deposit)="data">
                    {{ parseFloat(data.value || 0).toFixed(3) }} USDT
                  </template>
                  <template #cell(deposit_free)="data">
                    {{ data.value || '0' }} USDT
                  </template>
                  <template #cell(status)="data">
                    <span style="font-weight: bold" :class="`text-${data.value == 'enabled' ? 'success' : 'danger'}`">{{ convertStatusName(data.value, data.item.is_status_updated) }}</span>
                  </template>
                  <template #cell(asset)="data">
                    {{ symbols[data.value] }}
                  </template>
                  <template #cell(actions)="data">
                    <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                      <b-link class="action-label" title="Удалить" @click.stop="onTrashLabelClick(data.item.id)"><b-icon icon="trash"></b-icon></b-link>
                    </div>
                  </template>
                  <template #empty="scope">
                    <center>
                      {{ scope.emptyText }}
                    </center>
                  </template>
                </b-table>
              </b-tab>
              <b-tab title="Infinity Trade">
                <b-table @row-clicked="onItRowClick" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="settingsItList.items" :fields="settingsItList.fields" show-empty :emptyText="emptyTableText">
                  <template #cell(incomes_today)="data">
                    {{ parseFloat(data.value || 0).toFixed(3) }} USDT
                  </template>
                  <template #cell(free_deposit)="data">
                    {{ parseFloat(data.value || 0).toFixed(3) }} USDT
                  </template>
                  <template #cell(deposit_free)="data">
                    {{ data.value || '0' }} USDT
                  </template>
                  <template #cell(status)="data">
                    <span style="font-weight: bold" :class="`text-${data.value == 'enabled' ? 'success' : 'danger'}`">{{ convertStatusName(data.value, data.item.is_status_updated) }}</span>
                  </template>
                  <template #cell(asset)="data">
                    {{ symbols[data.value] }}
                  </template>
                  <template #cell(actions)="data">
                    <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                      <b-link class="action-label" title="Удалить" @click.stop="onItTrashLabelClick(data.item.id)"><b-icon icon="trash"></b-icon></b-link>
                    </div>
                  </template>
                  <template #empty="scope">
                    <center>
                      {{ scope.emptyText }}
                    </center>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal id="add-asset-modal" title="Добавить" hide-footer>
      <b-overlay :show="addSettingForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-tabs v-model="activeAddSettingTab" content-class="mt-3">
        <b-tab title="Spot & Futures">
          <b-form @submit.prevent="onAddAssetFormSubmit">
            <b-row>
              <b-col class="pr-1">
                <b-form-group label="Биржа">
                  <b-form-select v-model="addSettingForm.apiKeyPair" :options="keyTypes" size="sm"></b-form-select>
                </b-form-group>
                <b-form-group label="Депозит">
                  <b-form-input type="number" min="10" step="0.1" v-model="addSettingForm.deposit" size="sm" placeholder="Свободный депозит"></b-form-input>
                </b-form-group>
                <b-form-group label="Размер коридора">
                  <b-form-input type="number" min="10" step="0.1" v-model="addSettingForm.corridorSize" size="sm" placeholder="Размер коридора"></b-form-input>
                </b-form-group>
                <b-form-group label="Проскальзывание">
                  <b-form-input type="number" max="5" min="0.1" step="0.01" v-model="addSettingForm.slip" size="sm" placeholder="Проскальзывание"></b-form-input>
                </b-form-group>
                <b-form-group label="Баланс монет">
                  <b-form-input type="number" min="0" step="0.000001" v-model="addSettingForm.coinsBalance" size="sm" placeholder="Баланс монет"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pl-1">
                <b-form-group label="Валютная пара">
                  <b-form-select v-model="addSettingForm.assetId" :options="availableSymbols" size="sm"></b-form-select>
                </b-form-group>
                <b-form-group label="Комиссия">
                  <b-form-input type="number" max="0.1" min="0.01" step="0.01" v-model="addSettingForm.fee" size="sm" placeholder="Комиссия"></b-form-input>
                </b-form-group>
                <b-form-group label="Коэф. профита">
                  <b-form-input type="number" max="3" min="0.2" step="0.01" v-model="addSettingForm.profitRatio" size="sm" placeholder="Коэф. профита"></b-form-input>
                </b-form-group>
                <b-form-group label="Доп. коэф. профита">
                  <b-form-input type="number" max="3" min="0.2" step="0.01" :disabled="!addSettingForm.isProfitRatioMajor" v-model="addSettingForm.profitRatioMajor" size="sm" placeholder="Доп. коэф. профита"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-3"/>
            <div class="mb-1">
              <b-form-checkbox v-model="addSettingForm.isProfitRatioMajor" switch>Изменить коэф. профита в случае падения</b-form-checkbox>
            </div>
            <div class="mb-3">
              <b-form-checkbox v-model="addSettingForm.stopAfterEc" switch>Остановка после проскальзывания</b-form-checkbox>
            </div>
            <div class="text-right">
              <b-button block variant="dark" type="submit" size="sm">Добавить</b-button>
            </div>
          </b-form>
        </b-tab>
        <b-tab title="Infinity Trade">
          <b-form @submit.prevent="onAddInTradeSettingFormSubmit">
            <b-row>
              <b-col class="pr-1">
                <b-form-group label="Биржа">
                  <b-form-select v-model="addInTradeSettingForm.apiKeyPair" :options="keyTypes" size="sm"></b-form-select>
                </b-form-group>
                <b-form-group label="Депозит">
                  <b-form-input type="number" min="10" step="0.1" v-model="addInTradeSettingForm.deposit" size="sm" placeholder="Свободный депозит"></b-form-input>
                </b-form-group>
                <b-form-group label="Шаг">
                  <b-form-input type="number" max="5" min="0.1" step="0.01" v-model="addInTradeSettingForm.step" size="sm" placeholder="Шаг (%)"></b-form-input>
                </b-form-group>
                <b-form-group label="Шаг перерасчёта">
                  <b-form-input type="number" min="10" max="200" step="1" v-model="addInTradeSettingForm.recalcEvery" size="sm" placeholder="Шаг перерасчёта (%)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pl-1">
                <b-form-group label="Валютная пара">
                  <b-form-select v-model="addInTradeSettingForm.assetId" :options="availableSymbols" size="sm"></b-form-select>
                </b-form-group>
                <b-form-group label="Размер сетки">
                  <b-form-input type="number" max="100" min="10" step="1" v-model="addInTradeSettingForm.coverageGrid" size="sm" placeholder="Размер сетки (%)"></b-form-input>
                </b-form-group>
                <b-form-group label="Размер фьючерса">
                  <b-form-input type="number" min="10" max="80" step="1" v-model="addInTradeSettingForm.futuresSize" size="sm" placeholder="Размер фьючерса (%)"></b-form-input>
                </b-form-group>
                <b-form-group label="Глубина просадки">
                  <b-form-input type="number" min="0" step="0.00001" v-model="addInTradeSettingForm.depth" size="sm" placeholder="Глубина просадки (%)"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="text-right">
              <b-button block variant="dark" type="submit" size="sm">Добавить</b-button>
            </div>
          </b-form>
        </b-tab>
      </b-tabs>

    </b-modal>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'TradePage',
    data() {
      return {
        activeSettingTab: 0,
        activeAddSettingTab: 0,
        addSettingForm: {
          corridorSize: null,
          coinsBalance: null,
          deposit: null,
          profitRatio: null,
          isProfitRatioMajor: false,
          profitRatioMajor: null,
          fee: null,
          apiKeyPair: null,
          assetId: null,
          state: null,
          slip: null,
          stopAfterEc: null
        },
        addInTradeSettingForm: {
          depth: null,
          apiKeyPair: null,
          assetId: null,
          deposit: null,
          coverageGrid: null,
          step: null,
          recalcEvery: null,
          futuresSize: null
        },
        symbols: [],
        userKeysXExchanges: {},
        availableAssets: [],
        availableSymbols: [],
        keyTypes: [],
        settingsItList: {
          state: null,
          items: [],
          fields: [
            {
              key: 'type',
              label: 'Биржа',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'asset',
              label: 'Валюта',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'status',
              label: 'Состояние',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'free_deposit',
              label: 'Депозит',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'incomes_today',
              label: 'Доход (сутки)',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'actions',
              label: 'Удалить'
            },
          ]
        },
        settingsList: {
          state: null,
          items: [],
          fields: [
            {
              key: 'type',
              label: 'Биржа',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'asset',
              label: 'Валюта',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'status',
              label: 'Состояние',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'free_deposit',
              label: 'Депозит',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'incomes_today',
              label: 'Доход (сутки)',
              sortable: true,
              tdClass: 'clickable',
            },
            {
              key: 'actions',
              label: 'Удалить'
            },
          ]
        }
      }
    },
    mounted() {
      // Если убрать таймаут, activeSettingTab переключаться не будет. 
      setTimeout(() => {
        this.activeSettingTab = parseInt(localStorage.getItem('activeSettingTab') || '0')
      }, 1)
      setTimeout(() => {
        this.activeAddSettingTab = parseInt(localStorage.getItem('activeAddSettingTab') || '0')
      }, 1)
      this.getPageData()
    },
    watch: {
      'activeSettingTab': function(value) {
        localStorage.setItem('activeSettingTab', value)
      },
      'activeAddSettingTab': function(value) {
        localStorage.setItem('activeAddSettingTab', value)
      },
      'addSettingForm.apiKeyPair': function(value) {
        let results = []
        this.availableAssets.forEach((el, index) => {
          let cross_value = this.userKeysXExchanges[value]
          if(el['key_type_id'] == cross_value) {
            let symbol_id = el['symbol_id']
            let itm = {
              'value': el['id'],
              'text': this.symbols[symbol_id],
            }
            results.push(itm)
          }
          if(index == this.availableAssets.length-1) {
            this.addSettingForm.assetId = null
            this.availableSymbols = results
          }
        })
      },
      'addInTradeSettingForm.apiKeyPair': function(value) {
        let results = []
        this.availableAssets.forEach((el, index) => {
          let cross_value = this.userKeysXExchanges[value]
          if(el['key_type_id'] == cross_value) {
            let symbol_id = el['symbol_id']
            let itm = {
              'value': el['id'],
              'text': this.symbols[symbol_id],
            }
            results.push(itm)
          }
          if(index == this.availableAssets.length-1) {
            this.addInTradeSettingForm.assetId = null
            this.availableSymbols = results
          }
        })
      },
      'availableSymbols': function(value) {
        if(value.length == 0) {
          let itm = {
            'value': null,
            'text': 'Монеты недоступны',
            'disabled': true
          }
          value.unshift(itm)
        } else if(value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Выберите монету',
            'disabled': true
          }
          value.unshift(itm)
        }
      },
      'keyTypes': function(value) {
        if(value.length == 0) {
          let itm = {
            'value': null,
            'text': 'Биржи недоступны',
            'disabled': true
          }
          value.unshift(itm)
        } else if(value[0]['value'] != null) {
          let itm = {
            'value': null,
            'text': 'Выберите биржу',
            'disabled': true
          }
          value.unshift(itm)
        }
      }
    },
    methods: {
      onRowClick(item) {
        this.$router.push({ name: 'TradeAssetPage', params: {id: item.id} });
      },
      onItRowClick(item) {
        this.$router.push({ name: 'TradeAssetItPage', params: {id: item.id} });
      },
      onItTrashLabelClick(assetId) {
        let args = {
          'id': assetId,
          'type': 'it'
        }
        let data = this.serializeParams(args)
        apiRequest.post('remove_asset', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getPageData(false)
          }
          this.showAlert(success, response.data['message'])
        })
      },
      onRefreshButtonClick() {
        this.getPageData()
      },
      getPageData(updateAssetsInfo=true) {
        this.settingsList.state = this.dataStates.LOADING
        apiRequest.get('get_trading_settings')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.settingsList.items = response.data['data']['settings']
            this.settingsItList.items = response.data['data']['settings_it']
            if(updateAssetsInfo) {
              this.symbols = response.data['data']['symbols']
              this.availableAssets = response.data['data']['available_assets']
              this.keyTypes = response.data['data']['key_types']
              for(let i=0;i<this.keyTypes.length;i++) {
                let key = this.keyTypes[i]['value']
                let value = this.keyTypes[i]['key_type_id']
                this.userKeysXExchanges[key] = value
              }
              this.availableSymbols = []
            }
            this.settingsList.state = this.dataStates.LOADED
            this.settingsItList.state = this.dataStates.LOADED
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch(() => {
          this.settingsList.state = this.dataStates.ERROR
        })
      },
      onAddButtonClick() {
        this.$bvModal.show('add-asset-modal')
      },
      convertStatusName(status, is_upd) {
        if(status == 'enabled') {
          return is_upd ? 'Активен' : 'Запускается'
        } else if(status.endsWith('disabled')) {
          return is_upd ? 'Отключён' : 'Отключается'
        } else if(status.endsWith('restarted')) {
          return is_upd ? 'Перезагрузка' : 'Перезагружается'
        }
        return '...'
      },
      onAddAssetFormSubmit() {
        let profitRatioMajor = this.addSettingForm.isProfitRatioMajor ? this.addSettingForm.profitRatioMajor : null
        let args = {
          'corridor_size': this.addSettingForm.corridorSize,
          'coins_balance': this.addSettingForm.coinsBalance,
          'deposit': this.addSettingForm.deposit,
          'profit_ratio': this.addSettingForm.profitRatio,
          'profit_ratio_major': profitRatioMajor,
          'fee': this.addSettingForm.fee,
          'api_key_id': this.addSettingForm.apiKeyPair,
          'asset_id': this.addSettingForm.assetId,
          'slip': this.addSettingForm.slip,
          'stop_after_ec': this.addSettingForm.stopAfterEc ? '1' : '0',
        }
        this.addSettingForm.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('add_trading_setting', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getPageData(false)
            this.$bvModal.hide('add-asset-modal')
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.addSettingForm.state = this.dataStates.LOADED
        })
      },
      onAddInTradeSettingFormSubmit() {
        let args = {
          'api_key_id': this.addInTradeSettingForm.apiKeyPair,
          'asset_id': this.addInTradeSettingForm.assetId,
          'deposit': this.addInTradeSettingForm.deposit,
          'grid': this.addInTradeSettingForm.coverageGrid,
          'depth': this.addInTradeSettingForm.depth,
          'step': this.addInTradeSettingForm.step,
          'recalc_every': this.addInTradeSettingForm.recalcEvery,
          'futures_size': this.addInTradeSettingForm.futuresSize,
          'type': 'it'
        }
        this.addInTradeSettingForm.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('add_trading_setting', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getPageData(false)
            this.$bvModal.hide('add-asset-modal')
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.addInTradeSettingForm.state = this.dataStates.LOADED
        })
      },
      onTrashLabelClick(assetId) {
        let args = {
          'id': assetId,
        }
        let data = this.serializeParams(args)
        apiRequest.post('remove_asset', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.getPageData(false)
          }
          this.showAlert(success, response.data['message'])
        })
      }
    }
  }
</script>
