<template>
  <div>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-card class="p-0" no-body>
          <b-card-body class="p-2">
            <b-row>
              <b-col>
                <b-button size="sm" :disabled="pageState == dataStates.LOADING" @click="onBackButtonClick" variant="dark">Назад</b-button>
              </b-col>
              <!--<b-col cols="7" sm="5" md="4" lg="3" xl="2" class="text-right">
                <b-form-select :disabled="pageState == dataStates.LOADING" size="sm" v-model="currentAsset" :options="assets"></b-form-select>
              </b-col>!-->
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2 justify-content-center">
      <b-col sm="12">
        <b-overlay :show="pageState == dataStates.LOADING">
          <b-card>
            <b-row class="justify-content-center">
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Сегодня: <b>{{ parseFloat(incomes.today || '0').toFixed(3) }} USDT</b>
                </div>
              </b-col>
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Месяц: <b>{{ parseFloat(incomes.month || '0').toFixed(3) }} USDT</b>
                </div>
              </b-col>
              <!--<b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Всего: <b>{{ parseFloat(incomes.totally || '0').toFixed(3) }} USDT</b>
                </div>
              </b-col>!-->
              <b-col class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  Депозит: <b>{{ parseFloat(setting.freeDeposit || 0).toFixed(3) || '0' }} USDT</b>
                </div>
              </b-col>
              <b-col class="mb-3" xl="3" lg="3" md="3" sm="6">
                <div class="info-block">
                  В работе (сессия): <b>{{ parseFloat(setting.usedDeposit || 0).toFixed(3) || '0' }} USDT</b>
                </div>
              </b-col>

              <b-col class="mb-3" xl="3" lg="3" md="3" sm="6">
                <div class="info-block">
                  В работе (всего): <b>{{ parseFloat(setting.usedDepositTotal || 0).toFixed(3) || '0' }} USDT</b>
                </div>
              </b-col>

              <!--<b-col v-if="setting.timeLeft != null && setting.timeLeft <= 300" class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block">
                  <b>Работает</b>
                </div>
              </b-col>
              <b-col v-else class="mb-3" xl="2" lg="3" md="3" sm="6">
                <div class="info-block-bad">
                  <b>Не работает</b>
                </div>
              </b-col>!-->
            </b-row>
            <b-form-group class="mb-2">
              <b-form-textarea rows="10" v-model="log" no-resize readonly></b-form-textarea>
            </b-form-group>
            <div class="float-sm-left">
              <b-button-group class="d-flex" v-if="!isBotEnabled && isStatusUpdated || isBotEnabled && !isStatusUpdated">
                <b-button variant="success" :disabled="!isStatusUpdated" size="sm" @click="onStartButtonClick">Запустить</b-button>
              </b-button-group>
              <b-button-group class="d-flex" v-if="!isBotEnabled && !isStatusUpdated">
                <b-button variant="primary" :disabled="isUrgnetlyAction" size="sm" @click="onCancelActionClick">Отменить действие</b-button>
              </b-button-group>
              <b-button-group class="d-flex" v-else-if="isBotEnabled && isStatusUpdated">
                <b-button variant="danger" size="sm" @click="onStopButtonClick">Остановить</b-button>
                <b-button variant="info" size="sm" @click="onRestartButtonClick">Перезапустить</b-button>
              </b-button-group>
            </div>
            <div class="float-sm-right mt-2 mt-sm-0">
              <b-button-group size="sm" class="d-flex">
                <b-button variant="success" v-b-modal.holding-orders-modal>Холдинги</b-button>
                <b-button variant="success" v-b-modal.settings-modal>Настройки</b-button>
                <b-button variant="success" title="Калькулятор" v-b-modal.calc-modal><b-icon icon="calculator"></b-icon></b-button>
                <b-button variant="success" title="Очистить журнал" @click="onCleanButtonClick"><b-icon icon="trash"></b-icon></b-button>
              </b-button-group>
            </div>
            <!--<div class="text-center" v-else>
              <b-button-group>
                <template v-if="!isBotEnabled && isStatusUpdated || isBotEnabled && !isStatusUpdated">
                  <b-button variant="success" :disabled="!isStatusUpdated" size="sm" @click="onStartButtonClick">Запустить</b-button>
                </template>
                <template v-if="!isBotEnabled && !isStatusUpdated">
                  <b-button variant="primary" :disabled="isUrgnetlyAction" size="sm" @click="onCancelActionClick">Отменить</b-button>
                </template>
                <template v-else-if="isBotEnabled && isStatusUpdated">
                  <b-button variant="danger" size="sm" @click="onStopButtonClick">Остановить</b-button>
                  <b-button variant="info" size="sm" @click="onRestartButtonClick">Перезапустить</b-button>
                </template>
                <b-button variant="success" v-b-modal.holding-orders-modal size="sm">Холдинги</b-button>
                <b-button variant="success" v-b-modal.settings-modal size="sm">Настройки</b-button>
                <b-button variant="success" @click="onCleanButtonClick" size="sm">Очистить</b-button>
              </b-button-group>
            </div>!-->
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal id="calc-modal" size="lg" v-model="isCalcModalOpen" @show="onCalcModalShow" responsive title="Калькулятор" hide-footer>
      <b-row>
        <b-col class="pr-1">
          <b-form-group label="Депозит">
            <b-form-input type="number" min="10" step="0.1" v-model="calcForm.deposit" size="sm" placeholder="Свободный депозит"></b-form-input>
          </b-form-group>
          <b-form-group label="Шаг">
            <b-form-input type="number" max="5" min="0.1" step="0.01" v-model="calcForm.step" size="sm" placeholder="Шаг (%)"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="pl-1">
          <b-form-group label="Глубина просадки">
            <b-form-input type="number" max="100" min="1" step="1" v-model="calcForm.coverageGrid" size="sm" placeholder="Глубина просадки (%)"></b-form-input>
          </b-form-group>
          <b-form-group label="Размер фьючерса">
            <b-form-input type="number" min="10" max="80" step="1" v-model="calcForm.futuresSize" size="sm" placeholder="Размер фьючерса (%)"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="isNaN(this.calcResults.totalProfit)">
        <b-col>
          <div class="info-block-bad">
            <b>Неверно введены параметры</b>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="justify-content-center">
        <b-col class="pr-1">
          <div :class="calcBlockClass">
            Прибыль с продажи: <b>{{ calcResults.sellingProfit }} USDT</b>
          </div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div :class="calcBlockClass">
            Убыток с фьючерса: <b>{{ calcResults.closeFuturesLoss }} USDT</b>
          </div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div :class="calcBlockClass">
            Итог: <b>{{ calcResults.totalProfit }} USDT</b>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="holding-orders-modal" size="xl" responsive @show="onHoldingOrdersOpen" title="Ордера в холдинге" hide-footer>
      <b-overlay :show="holdingOrders.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-table selectable select-mode="multi" @row-selected="onOrdersRowsSelected" head-variant="light" borderless outlined responsive class="text-center table-custom mb-1" small hover :items="holdingOrders.items" :fields="holdingOrders.fields" show-empty :emptyText="emptyTableText">
        <template #empty="scope">
          <center>
            {{ scope.emptyText }}
          </center>
        </template>
        <template #cell(fee)="data">
          {{ parseFloat(data.value || 0).toFixed(3) }}
        </template>
        <template #cell(spent)="data">
          {{ parseFloat(data.value || 0).toFixed(3) }}
        </template>
        <template #cell(is_avg)="data">
          <b-link @click="onCancelAvgOrdersButtonClick(data.item.id)" v-if="data.value > 0">
            <b-badge variant="danger"><b-icon icon="x"></b-icon></b-badge>
          </b-link>
        </template>
      </b-table>
      <div class="text-right mt-3">
        <b-button variant="success" class="mr-1" size="sm" :disabled="!isAvgOrdersAvailable" @click="onAvgOrdersButtonClick">Усреднить</b-button>
        <b-button variant="danger" size="sm" :disabled="!isCancelOrdersAvailable" @click="onCancelOrdersButtonClick">Отменить</b-button>
      </div>
    </b-modal>

    <b-modal id="settings-modal" v-model="isSettingsModalShown" title="Настройки" hide-footer>
      <b-overlay :show="settingsForm.state == dataStates.LOADING" no-wrap>
        <template #overlay>
        </template>
      </b-overlay>
      <b-form @submit.prevent="onSettingsFormSubmit">
        <b-row>
          <b-col class="pr-1">
            <b-form-group label="Депозит">
              <b-form-input type="number" min="10" step="0.1" v-model="settingsForm.deposit" size="sm" placeholder="Свободный депозит"></b-form-input>
            </b-form-group>
            <b-form-group label="Шаг">
              <b-form-input type="number" max="5" min="0.1" step="0.01" v-model="settingsForm.step" size="sm" placeholder="Шаг (%)"></b-form-input>
            </b-form-group>
            <b-form-group label="Шаг перерасчёта">
              <b-form-input type="number" min="10" max="200" step="1" v-model="settingsForm.recalcEvery" size="sm" placeholder="Шаг перерасчёта (%)"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="pl-1">
            <b-form-group label="Размер сетки">
              <b-form-input type="number" max="100" min="10" step="1" v-model="settingsForm.coverageGrid" size="sm" placeholder="Размер сетки (%)"></b-form-input>
            </b-form-group>
            <b-form-group label="Размер фьючерса">
              <b-form-input type="number" min="10" max="80" step="1" v-model="settingsForm.futuresSize" size="sm" placeholder="Размер фьючерса (%)"></b-form-input>
            </b-form-group>
            <b-form-group label="Глубина просадки">
              <b-form-input type="number" min="0" step="0.00001" v-model="settingsForm.depth" size="sm" placeholder="Глубина просадки (%)"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mt-0 mb-3"/>
        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button type="submit" variant="dark" size="sm">Сохранить</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'TradeAssetItPage',
    data() {
      return {
        setting: {
          usedDepositTotal: null,
          usedDeposit: null,
          freeDeposit: null,
          timeLeft: null
        },
        isCalcModalOpen: false,
        settingsForm: {
          deposit: null,
          coverageGrid: null,
          depth: null,
          step: null,
          recalcEvery: null,
          futuresSize: null
        },
        calcForm: {
          tooBadSettingsError: true,
          price: null,
          maxLv: null,
          mmt: null,
          multiplier: null,
          sizeRounding: null,
          priceRounding: null,
          holdingPart: null,
          holdingProfit: null,
          profitRatio: null,
          depositPerCorridor: null,
          fee: null,
          orderPrice: null,
          profit: null,
          loss: null,
          isDataLoading: true,
          deposit: null,
          coverageGrid: null,
          step: null,
          futuresSize: null
        },
        calcResults: {
          sellingProfit: null,
          closeFuturesLoss: null,
          totalProfit: null
        },
        holdingOrders: {
          state: null,
          items: [],
          selectedItems: [],
          fields: [
            {
              label: 'Дата создания',
              key: 'created_at',
              sortable: true,
            },
            {
              label: 'Цена',
              key: 'price',
              sortable: true,
            },
            {
              label: 'Объём',
              key: 'size',
              sortable: true,
            },
            {
              label: 'Затрачено',
              key: 'spent',
              sortable: true,
            },
            {
              label: 'Цена открытия',
              key: 'open',
              sortable: true,
            },
            {
              label: 'Комиссии',
              key: 'fee',
              sortable: true,
            },
            {
              label: '',
              key: 'is_avg'
            },
          ]
        },
        assets: [
        ],
        asset: {
          left: null,
          right: null
        },
        log: null,
        windowWidth: window.innerWidth,
        pageState: null,
        isBotEnabled: false,
        isStatusUpdated: true,
        isUrgnetlyAction: false,
        isSettingsModalShown: false,
        isAvgOrdersAvailable: false,
        isCancelOrdersAvailable: false,
        currentAsset: null,
        incomes: {
          month: null,
          today: null,
          totally: null,
        }
      }
    },
    computed: {
      calcBlockClass() {
        if(this.calcResults.totalProfit > 0) {
          return 'info-block'
        }
        return 'info-block-bad'
      }
    },
    mounted() {
      this.currentAsset = this.$route.params.id
      this.updatePageDataInterval = setInterval(this.getAssetData, 15000)
      this.getAssetData()
      this.getUserAssets()
      window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
      clearInterval(this.updatePageDataInterval)
      window.removeEventListener('resize', this.onResize)
    },
    watch: {
      currentAsset: function(newValue, oldValue) {
        if(oldValue != null) {
          clearInterval(this.updatePageDataInterval)
          this.$router.push({ name: 'TradeAssetItPage', params: { id: newValue } }).catch(()=>{})
        }
      },
      'calcForm.deposit': function() {
        this.calcEstProfitLoss()
      },
      'calcForm.coverageGrid': function() {
        this.calcEstProfitLoss()
      },
      'calcForm.step': function() {
        this.calcEstProfitLoss()
      },
      'calcForm.futuresSize': function() {
        this.calcEstProfitLoss()
      }
    },
    methods: {
      onCalcModalShow() {
        this.calcForm.isDataLoading = true
        apiRequest.post('get_fut_asset_info', `setting_id=${this.currentAsset}`)
        .then(response => {
          this.calcForm.maxLv = response.data['data']['max_leverage']
          this.calcForm.mmt = response.data['data']['mt_margin']
          this.calcForm.multiplier = response.data['data']['multiplier']
          this.calcForm.sizeRounding = response.data['data']['size_round_str']
          this.calcForm.priceRounding = response.data['data']['price_round_str']
          this.calcForm.price = parseFloat(response.data['data']['price'])
        })
        .finally(() => {
          this.calcForm.isDataLoading = false
          this.calcEstProfitLoss()
        })
      },
      calcEstProfitLoss() {
        let stepsCount = this.calcForm.coverageGrid / this.calcForm.step
        let amountPerOrder = this.calcForm.deposit / stepsCount
        let step = this.calcForm.price * this.calcForm.step / 100
        const spotFee = 0.1
        const futuresFee = 0.06
        let bought = amountPerOrder / this.calcForm.price
        let slProfit = bought * (this.calcForm.price + step) - amountPerOrder

        let futPart = this.calcForm.futuresSize / 100
        let realSpotFee = amountPerOrder * spotFee / 100 * 2
        let realFuturesFee = amountPerOrder * futPart * futuresFee / 100 * 2

        this.calcResults.sellingProfit = slProfit - realSpotFee
        this.calcResults.closeFuturesLoss = (slProfit * futPart) + realFuturesFee

        this.calcResults.totalProfit = this.calcResults.sellingProfit - this.calcResults.closeFuturesLoss

        this.calcResults.sellingProfit = this.calcResults.sellingProfit.toFixed(4)
        this.calcResults.closeFuturesLoss = this.calcResults.closeFuturesLoss.toFixed(4)
        this.calcResults.totalProfit = this.calcResults.totalProfit.toFixed(4)
      },
      onResize() {
        this.windowWidth = window.innerWidth
      },
      getUserAssets() {
        apiRequest.get('get_user_assets')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.assets = response.data['data']
          }
        })
      },
      onBackButtonClick() {
        this.$router.push({ name: 'TradePage' }).catch(()=>{})
      },
      onCleanButtonClick() {
        this.$bvModal.msgBoxConfirm('Очистить журнал бота?', {
          title: 'Подтверждение',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value) {
            let args = {
              'id': this.currentAsset
            }
            let data = this.serializeParams(args)
            apiRequest.post('clean_asset_it_log', data)
            .then(response => {
              let success = false
              if(response.data['status'] == 'success') {
                success = true
                this.log = null
              }
              this.showAlert(success, response.data['message'])
            })
          }
        })
      },
      onRestartButtonClick() {
        this.setAssetStatus('restarted')
      },
      onStopButtonClick() {
        let msg = [
          this.$createElement('div', '1. Остановить работу и выключить бота.'),
          this.$createElement('div', '2. Переместить активный ордер в холдинг и прервать работу бота.'),
        ]
        this.$bvModal.msgBoxConfirm(msg, {
          title: 'Прервать',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'secondary',
          okTitle: 'Вариант 2',
          cancelVariant: 'secondary',
          cancelTitle: 'Вариант 1',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            this.setAssetStatus('urgently_disabled', false, true)
          } else if(value === false) {
            this.setAssetStatus('disabled')            
          }
        })
      },
      onCancelActionClick() {
        if(this.isBotEnabled) {
          this.setAssetStatus('disabled', true, false, true)
        } else {
          this.setAssetStatus('enabled', true, false, true)
        }
      },
      onStartButtonClick() {
        this.setAssetStatus('enabled')
      },
      onAvgOrdersButtonClick() {
        let selected = this.holdingOrders.selectedItems.map(n => n['id'])
        let args = {
          'setting_id': this.currentAsset,
          'order_ids': selected.join(','),
          'type': 'it'
        }
        this.holdingOrders.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('avg_orders', data)
        .then(response => {
          if(response.data['status'] == 'success') {
            let price = response.data['data']['price']
            let size = response.data['data']['size']
            let msg = `Будет размещён ордер по усреднённой цене ${price} объёмом ${size}, а выбранные будут отменены. Вы уверены?`
            this.$bvModal.msgBoxConfirm(msg, {
              title: 'Подтверждение',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Да',
              cancelTitle: 'Нет',
              footerClass: 'p-2',
              hideHeaderClose: false
            })
            .then(value => {
              if(value) {
                let args = {
                  'setting_id': this.currentAsset,
                  'order_ids': selected.join(','),
                  'place_orders': '1'
                }
                let data = this.serializeParams(args)
                this.holdingOrders.state = this.dataStates.LOADING
                apiRequest.post('avg_orders', data)
                .then(response => {
                  if(response.data['status'] != 'success') {
                    this.showAlert(false, response.data['message'])
                  }
                })
                .finally(() => {
                  this.onHoldingOrdersOpen()
                })
              }
            })
          } else {
            this.showAlert(false, response.data['message'])
          }
        })
        .finally(() => {
          this.holdingOrders.state = this.dataStates.LOADED
        })
      },
      onCancelOrdersButtonClick() {
        this.$bvModal.msgBoxConfirm('Вы уверены что хотите отменить выбранные ордера?', {
          title: 'Подтверждение',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelVariant: 'success',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            let selected = this.holdingOrders.selectedItems.map(n => n['id'])
            let args = {
              'setting_id': this.currentAsset,
              'order_ids': selected.join(','),
              'type': 'it'
            }
            this.holdingOrders.state = this.dataStates.LOADING
            let data = this.serializeParams(args)
            apiRequest.post('cancel_orders', data)
            .then(response => {
              if(response.data['status'] != 'success') {
                this.showAlert(false, response.data['message'])
              }
            })
            .finally(() => {
              this.onHoldingOrdersOpen()
            })
          }
        })
      },
      onCancelAvgOrdersButtonClick() {
        alert(1)
      },
      onSettingsFormSubmit() {
        let args = {
          'asset_id': this.currentAsset,
          'deposit': this.settingsForm.deposit,
          'coverage_grid': this.settingsForm.coverageGrid,
          'depth': this.settingsForm.depth,
          'step': this.settingsForm.step,
          'recalc_every': this.settingsForm.recalcEvery,
          'futures_size': this.settingsForm.futuresSize
        }
        this.settingsForm.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('set_trading_setting_it', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.$bvModal.hide('settings-modal')
            this.getAssetData()
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.settingsForm.state = this.dataStates.LOADED
        })
      },
      onRefreshButtonClick() {
        this.getAssetData()
      },
      getAssetData() {
        this.pageState = this.dataStates.LOADING
        apiRequest.post('get_trading_setting_it', `id=${this.currentAsset}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            this.pageState = this.dataStates.LOADED
            this.incomes.month = response.data['data']['incomes']['month']
            this.incomes.today = response.data['data']['incomes']['today']
            this.incomes.totally = response.data['data']['incomes']['totally']
            this.setting.usedDeposit = response.data['data']['asset']['used_deposit']
            this.setting.usedDepositTotal = response.data['data']['asset']['used_deposit_total']
            this.setting.freeDeposit = response.data['data']['asset']['free_deposit']
            this.setting.timeLeft = response.data['data']['asset']['time_left']
            if(!this.isSettingsModalShown) {
              this.settingsForm.deposit = response.data['data']['asset']['free_deposit']
              this.settingsForm.coverageGrid = response.data['data']['asset']['grid']
              this.settingsForm.depth = response.data['data']['asset']['depth']
              this.settingsForm.step = response.data['data']['asset']['step']
              this.settingsForm.recalcEvery = response.data['data']['asset']['recalc_every']
              this.settingsForm.futuresSize = response.data['data']['asset']['futures_size']
            }

            if(!this.isCalcModalOpen) {
              this.calcForm.deposit = this.settingsForm.deposit
              this.calcForm.coverageGrid = this.settingsForm.coverageGrid
              this.calcForm.step = this.settingsForm.step
              this.calcForm.futuresSize = this.settingsForm.futuresSize
            }

            this.isStatusUpdated = response.data['data']['asset']['is_status_updated'] == 1
            this.isBotEnabled = response.data['data']['asset']['status'] == 'enabled'
            this.isUrgnetlyAction = response.data['data']['asset']['status'].startsWith('urgently')
            this.asset.left = response.data['data']['symbol']['asset_left']
            this.asset.right = response.data['data']['symbol']['asset_right']
            this.log = response.data['data']['log']
            this.fixTableLabels()
          } else {
            if(!this.isResponseHasRedirect(response.data)) {
              throw 'Error'
            }
          }
        })
        .catch((exc) => {
          console.log(exc)
          this.$router.push({ name: 'TradePage' }).catch(()=>{})
          this.pageState = this.dataStates.ERROR
        })
      },
      fixTableLabels() {
        for(let i=0;i<this.holdingOrders.fields.length;i++) {
          if(this.holdingOrders.fields[i]['key'] === 'spent') {
            this.holdingOrders.fields[i]['label'] = `Потрачено (${this.asset.right})`
          } else if(this.holdingOrders.fields[i]['key'] === 'balance') {
            this.holdingOrders.fields[i]['label'] = `Остаток (${this.asset.right})`
          }
        }
      },
      updateAvgState() {
        let selected = this.holdingOrders.selectedItems.map(n => n['id'])
        if(selected.length >= 1) {
          this.isCancelOrdersAvailable = true
          if(selected.length >= 2) {
            this.isAvgOrdersAvailable = true
          } else {
            this.isAvgOrdersAvailable = false
          }
        } else {
          this.isCancelOrdersAvailable = false
          this.isAvgOrdersAvailable = false
        }
      },
      onOrdersRowsSelected(items) {
        this.holdingOrders.selectedItems = items
        this.updateAvgState()
      },
      setAssetStatus(status, setUpdatedState=false, setCancelOrders=false, back=false) {
        let args = {
          'id': this.currentAsset,
          'status': status,
          'back': back ? 1:0,
          'type': 'it'
        }
        if(setUpdatedState) {
          args['updated'] = 1
        }
        if(setCancelOrders) {
          args['cancel_orders'] = 1
        }
        let data = this.serializeParams(args)
        apiRequest.post('set_trading_setting_status', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.isBotEnabled = status == 'enabled'
            this.isUrgnetlyAction = status.startsWith('urgently')
            this.isStatusUpdated = setUpdatedState
            setTimeout(this.getAssetData, 3500)
          }
          this.showAlert(success, response.data['message'])
        })
      },
      onHoldingOrdersOpen() {
        let args = {
          'setting_id': this.currentAsset,
          'type': 'it'
        }
        this.holdingOrders.state = this.dataStates.LOADING
        let data = this.serializeParams(args)
        apiRequest.post('get_holding_orders', data)
        .then(response => {
          let success = false
          if(response.data['status'] == 'success') {
            success = true
            this.holdingOrders.items = response.data['data']
            for(let i=0;i<this.holdingOrders.items.length;i++) {
              this.holdingOrders.items[i]['isSelected'] = false
            }
            this.$bvModal.hide('settings-modal')
          }
          this.showAlert(success, response.data['message'])
        })
        .finally(() => {
          this.updateAvgState()
          this.holdingOrders.state = this.dataStates.LOADED
          this.$forceUpdate()
        })
      }
    }
  }
</script>