<template>
  <div class="fade-enter-active fade-enter">
    <b-container fluid class="mb-3">
      <b-row>
        <b-col ref="sidebarCol" :sm="isSidebarVisible ? '2' : '0'">
          <b-sidebar :width="sidebarWidth" bg-variant="sidenav-color" no-slide z-index="11" text-variant="light" v-model="isSidebarVisible" :no-close-on-route-change="noCloseOnRouteChange" no-close-on-esc>
            <b-nav class="sidenav" vertical>
              <b-nav-item :to="{ name: 'DashboardPage' }" exact><b-icon icon="person-fill"></b-icon>Кабинет</b-nav-item>
              <b-nav-item :to="{ name: 'TradePage' }" exact-path><b-icon icon="bar-chart-line-fill"></b-icon>Торговля</b-nav-item>
              <b-nav-item :to="{ name: 'ApiKeysPage' }" exact><b-icon icon="key-fill"></b-icon>Ключи</b-nav-item>
              <b-nav-item :to="{ name: 'StatsPage' }" exact><b-icon icon="calendar-week-fill"></b-icon>Статистика</b-nav-item>
              <b-nav-item :to="{ name: 'HoldingOrdersPage' }" exact><b-icon icon="diagram2-fill"></b-icon>Ордера</b-nav-item>
              <b-nav-item :to="{ name: 'ReferralsPage' }" exact><b-icon icon="people-fill"></b-icon>Рефералы</b-nav-item>
              <b-nav-item :to="{ name: 'TermsPage' }" exact><b-icon icon="chat-square-text-fill"></b-icon>Памятка</b-nav-item>
              <b-nav-item :to="{ name: 'SupportPage' }" exact><b-icon icon="people-fill"></b-icon>Поддержка</b-nav-item>
            </b-nav>
            <template #title>
             <div class="pb-1 header-logo" style="width: 100%; color: #fff;">
               FBM BOT
             </div>
            </template>
            <template #footer>
             <b-nav vertical class="mb-1 sidenav">
               <b-nav-item @click="onLogoutButtonClick" exact><b-icon icon="power"></b-icon> Выход</b-nav-item>
             </b-nav>
            </template>
          </b-sidebar>
        </b-col>
        <b-col class="p-0 pr-0 justify-content-center">
          <b-navbar class="pb-2 navbar-custom" sticky style="z-index: 10" type="light" variant="white">
            <b-navbar-nav class="navbar-nav-custom">
              <b-nav-item @click="onBurgerClick"><b-icon icon="list-ul"></b-icon></b-nav-item>
            </b-navbar-nav>
            <b-navbar-brand>{{ $route.meta.title }}</b-navbar-brand>
            <b-navbar-nav class="navbar-nav-custom ml-auto">
              <b-nav-item right class="mr-1" v-b-modal.timezone-modal><b-icon icon="clock"></b-icon></b-nav-item>
              <b-nav-item right class="mr-1" v-b-modal.notifications-modal><b-icon icon="chat-left-text"></b-icon></b-nav-item>
              <b-nav-item right v-if="isAddExists" class="mr-1" @click="onAddButtonClick"><b-icon icon="plus-circle"></b-icon></b-nav-item>
              <b-nav-item right v-if="isUpdateExists" @click="onUpdateButtonClick"><b-icon icon="arrow-clockwise"></b-icon></b-nav-item>
            </b-navbar-nav>
          </b-navbar>
          <b-container fluid>
            <transition mode="out-in" name="fade">
              <router-view ref="routerObject" :key="$route.fullPath"></router-view>
            </transition>
          </b-container>
        </b-col>
        <b-modal id="timezone-modal" @show="onTimeZoneModalOpen" size="md" responsive title="Часовой пояс" hide-footer>
          <b-overlay :show="timeZones.state == dataStates.LOADING" no-wrap>
            <template #overlay>
            </template>
          </b-overlay>
          <b-input-group>
            <b-form-select text-field="name" value-field="id" :disabled="timeZones.state == dataStates.LOADING" size="sm" v-model="timeZones.current" :options="timeZones.items"></b-form-select>
            <b-input-group-append>
              <b-button size="sm" @click="onSaveTimeZoneButtonClick" variant="dark">Сохранить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-modal>
        <b-modal id="notifications-modal" size="lg" responsive title="Уведомления" hide-footer>
          <b-overlay :show="notifications.state == dataStates.LOADING" no-wrap>
          </b-overlay>
          <div class="text-center" v-if="notifications.items.length == 0">
            Нет недавних уведомлений.
          </div>
          <div v-else class="text-right mb-2">
            <small><b-link @click="onAllNotificationsDeleteClick">[Удалить все уведомления]</b-link></small>
          </div>
          <b-alert fade @dismissed="onNotificationDeleteClick(alert.id)" dismissible class="text-break mb-2" show v-for="alert in notifications.items" :key="alert.id" :variant="alert.variant">
            <b-row>
              <b-col>
                <b>{{ alert.title }}</b>
              </b-col>
              <b-col class="text-right">
                <b>{{ alert.created_at }}</b>
              </b-col>
            </b-row>
            <p class="mb-0 ws-pl">{{ alert.text }}</p>
          </b-alert>
        </b-modal>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import {apiRequest} from '../main.js'

  export default {
    name: 'PageTemplate',
    data() {
      return {
        notifications: {
          items: [],
          state: null
        },
        isSidebarVisible: true,
        noCloseOnRouteChange: false,
        sidebarWidth: '0px',
        resizeTimeout: null,
        isUpdateExists: false,
        isAddExists: false,
        timeZones: {
          items: [],
          state: null,
          current: 1
        },
      }
    },
    mounted() {
      this.isUpdateExists = (typeof this.$refs.routerObject.onRefreshButtonClick !== 'undefined')
      this.isAddExists = (typeof this.$refs.routerObject.onAddButtonClick !== 'undefined')
      this.$el.classList.remove('fade-enter')
      this.onWindowResize()
      window.addEventListener('resize', this.onResizeDone)
      this.getNotifications()
      setInterval(this.getNotifications, 45000)
    },
    beforeRouteUpdate(to, from, next) {
      try {
        let methods = to.matched[to.matched.length-1].components['default'].methods
        this.isUpdateExists = ('onRefreshButtonClick' in methods) 
        this.isAddExists = ('onAddButtonClick' in methods) 
      } catch {
        this.isUpdateExists = false
        this.isAddExists = false
      }
      next()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResizeDone)
    },
    methods: {
      onTimeZoneModalOpen() {
        this.timeZones.state = this.dataStates.LOADING
        apiRequest.get('get_timezones')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.timeZones.items = response.data['data']['tzs']
            this.timeZones.current = response.data['data']['tz'] ?? 1
          }
        })
        .finally(() => {
          this.timeZones.state = this.dataStates.LOADED
        })
      },
      onSaveTimeZoneButtonClick() {
        this.timeZones.state = this.dataStates.LOADING
        apiRequest.post('set_timezone', `time_zone=${this.timeZones.current}`)
        .finally(() => {
          this.timeZones.state = this.dataStates.LOADED
          this.$bvModal.hide('timezone-modal')
          if(typeof this.$refs.routerObject.onRefreshButtonClick !== 'undefined') {
            this.$refs.routerObject.onRefreshButtonClick()
          }
        })
      },
      getNotifications() {
        this.notifications.state = this.dataStates.LOADING
        apiRequest.get('get_notifications')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.notifications.items = response.data['data']
            for(let notification of this.notifications.items) {
              if(notification['is_read'] == 0) {
                this.showCustomAlert(notification['title'], notification['text'], notification['variant'])
              }
            }
          }
        })
        .finally(() => {
          this.notifications.state = this.dataStates.LOADED
        })
      },
      onAllNotificationsDeleteClick() {
        this.$bvModal.msgBoxConfirm('Удалить все уведомления?', {
          title: 'Подтверждение',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Да',
          cancelVariant: 'danger',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false
        })
        .then(value => {
          if(value === true) {
            this.notifications.state = this.dataStates.LOADING
            apiRequest.get('delete_notifications')
            .then(response => {
              if(response.data['status'] == 'success') {
                this.notifications.items = []
              }
            })
            .finally(() => {
              this.notifications.state = this.dataStates.LOADED
            })
          }
        })
      },
      onNotificationDeleteClick(id) {
        this.notifications.state = this.dataStates.LOADING
        apiRequest.post('delete_notification', `id=${id}`)
        .then(response => {
          if(response.data['status'] == 'success') {
            for(let [index, value] of this.notifications.items.entries()) {
              if(value['id'] == id) {
                this.notifications.items.splice(index, 1)
                break
              }
            }
          }
        })
        .finally(() => {
          this.notifications.state = this.dataStates.LOADED
        })
      },
      onWindowResize() {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          this.onResizeDone();
        }, 50);
      },
      onResizeDone(check=true) {
        if(check) {
          let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
          this.isSidebarVisible = width >= 1000
          this.noCloseOnRouteChange = width >= 1000
        }
        if(this.isSidebarVisible && this.$refs.sidebarCol.clientWidth == 0) {
          setTimeout(() => {
            this.onResizeDone(false)
          }, 10)
        } else {
          this.sidebarWidth = `${this.$refs.sidebarCol.clientWidth}px`
        }
      },
      onBurgerClick() {
        this.isSidebarVisible = !this.isSidebarVisible
        setTimeout(() => {
          if(this.$refs.sidebarCol.clientWidth > 0) {
            this.sidebarWidth = `${this.$refs.sidebarCol.clientWidth}px`
          }
        }, 1)
      },
      onUpdateButtonClick() {
        if(typeof this.$refs.routerObject.onRefreshButtonClick !== 'undefined') {
          this.$refs.routerObject.onRefreshButtonClick()
        }
      },
      onAddButtonClick() {
        if(typeof this.$refs.routerObject.onAddButtonClick !== 'undefined') {
          this.$refs.routerObject.onAddButtonClick()
        }
      },
      onLogoutButtonClick() {
        apiRequest.get('logout')
        .then(response => {
          if(response.data['status'] == 'success') {
            this.$el.classList.add('fade-enter')
            setTimeout(() => {
              this.$router.push({name: 'LoginPage'})
            }, 300)
          }
        })
      }
    }
  }
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
